import '../styles.scss';
import React from 'react';
import SemDados from '../SemDados';
import { NOME_PROJETO } from '../const';

const RegionalInvestimentoTabela = (props) => {
  const { data } = props;
  const titulo = `INVESTIMENTO ${NOME_PROJETO} - REGIONAL`;

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  // const maxVerba = Math.max(...data.map((item) => item.totalVerba));
  // const maxCusto = Math.max(...data.map((item) => item.totalCusto));

  // const maxValor = Math.max(maxVerba, maxCusto);

  const formatarValor = (valor) => {
    return `R$${valor.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    })}`;
  };

  // const getBarra = (verba, custo, nome) => {
  //   return (
  //     <td key={`ib-${nome}`}>
  //       <div className="celula-barra-ponto">
  //         <div
  //           className="barra-celula"
  //           style={{ width: `${(custo * 100) / maxValor}%` }}
  //         />
  //         <div
  //           className="ponto-celula"
  //           style={{ left: `${(verba * 100) / maxValor}%` }}
  //         />
  //       </div>
  //     </td>
  //   );
  // };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="tabela-container">
        <table className="tabela">
          <thead>
            <tr key="rh">
              <th
                className="fixed-header"
                style={{ width: '15%' }}
                aria-label="header"
                key="hn"
              >
                Regional (grupo)
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hcd"
              >
                Custo Diagramação
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hct"
              >
                Custo Impressão + Frete
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="htc"
              >
                Total Gasto
              </th>
              {/* <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="htv"
              >
                Verba Total
              </th> */}
              <th
                className="fixed-header"
                style={{ width: '15%' }}
                aria-label="header"
                key="hn2"
              >
                Regional (grupo)
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hts"
              >
                Total Solicitações
              </th>
              <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hpa"
              >
                % Aprovada
              </th>
              {/* <th
                className="fixed-header"
                style={{ width: '10%' }}
                aria-label="header"
                key="hb"
              /> */}
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              const { nome } = item;
              return (
                <tr key={`r-${nome}`}>
                  <td key={`in-${nome}`}>{nome}</td>
                  <td key={`icd-${nome}`}>
                    {formatarValor(item.custoDiagramacao)}
                  </td>
                  <td key={`ict-${nome}`}>
                    {formatarValor(item.custoImpressao + item.custoFrete)}
                  </td>
                  <td key={`itc-${nome}`}>{formatarValor(item.totalCusto)}</td>
                  {/* <td key={`itv-${nome}`}>{formatarValor(item.totalVerba)}</td> */}
                  <td key={`in2-${nome}`}>{nome}</td>
                  <td key={`its-${nome}`}>{item.totalSolicitacoes}</td>
                  <td key={`ipa-${nome}`}>{item.porcentagemAprovada}%</td>
                  {/* {getBarra(item.totalVerba, item.totalCusto, nome)} */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RegionalInvestimentoTabela;
