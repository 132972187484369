import api from 'api';

const qs = require('qs');

const PATH = '/RelatorioGrafico';

// const mockData = {
//   code: 200,
//   message: null,
//   jwtToken: null,
//   value: {
//     filtro: {
//       filtroSelecionado: {
//         ano: ['2022', '2023'],
//         mes: [
//           'outubro 2022',
//           'novembro 2022',
//           'dezembro 2022',
//           'janeiro 2023',
//           'fevereiro 2023',
//           'março 2023',
//           'abril 2023',
//           'maio 2023',
//           'junho 2023',
//           'julho 2023',
//           'agosto 2023',
//           'setembro 2023',
//           'outubro 2023',
//           'dezembro 2023',
//         ],
//         regional: ['NO/NE', 'SAO PAULO', 'SE/CO', 'SUL', 'TRADE COORPORATIVO'],
//         canal: [
//           'AS Nacional',
//           'C&C Nacional',
//           'C&C Regional',
//           'HSA',
//           'KAR',
//           'SEM REGRA',
//         ],
//         atividade: [
//           null,
//           'COMEMORATIVOS',
//           'CONGELADOS',
//           'EMBUTIDOS',
//           'FRIOS',
//           'IN NATURA',
//           'MARGARINAS',
//           'SOBREMESA',
//         ],
//         categoria: [
//           null,
//           'AFIAMBRADO',
//           'APRESUNTADO',
//           'BACON',
//           'BACON (DOMESTICO)',
//           'BACON (GRANEL)',
//           'BATATA FRITA',
//           'BROCOLI',
//           'COMEMORATIVOS',
//           'CONGELADOS',
//           'EMPANADO (DOMESTICO)',
//           'EMPANADO (GRANEL)',
//           'EMPANADOS',
//           'ERVILHA',
//           'FATIADO ESPECIAIS',
//           'FATIADO MORTADELA MEDIA',
//           'FATIADO PEITO PERU',
//           'FATIADO PRESUNTO',
//           'FATIADO QUEIJO',
//           'FATIADO SALAME',
//           'FATIADOS',
//           'FATIADOS SOLTÍSSIMO',
//           'FRANGO ASA',
//           'FRANGO ASA SP',
//           'FRANGO CORTES ESPECIAIS',
//           'FRANGO CORTES ESPECIAIS SP',
//           'FRANGO INTEIRO BROILER SP',
//           'FRANGO MIUDOS',
//           'FRANGO MIUDOS SP',
//           'FRANGO PEITO',
//           'FRANGO PEITO SP',
//           'FRANGO PERNA',
//           'FRANGO PERNA SP',
//           'FRIOS ESPECIAIS',
//           'FRIOS LIGHT',
//           'HAMBURGUER',
//           'HAMBURGUER (DOMESTICO)',
//           'HAMBURGUER (GRANEL)',
//           'IN NATURA - SUÍNOS FÁCIL',
//           'IN NATURA-BANDEJAS',
//           'IN NATURA-CORTES DE FRANGO',
//           'IN NATURA-CORTES DE FRANGO - FOOD SERVICES',
//           'IN NATURA-FRANGO CONGELADO',
//           'IN NATURA-FRANGO FÁCIL',
//           'IN NATURA-FRANGO INTEIRO',
//           'IN NATURA-IQF',
//           'IN NATURA-NA MEDIDA',
//           'IN NATURA-PACOTE',
//           'IN NATURA-SUINO CORTES',
//           'IN NATURA-SUÍNO PERNIL',
//           'IN NATURA-SUÍNOS CONGELADOS',
//           'IN NATURA-SUÍNOS FÁCIL',
//           'IN NATURA-SUINOS OUTROS CORTES',
//           'IN NATURA-SUÍNOS SALGADOS',
//           'INOVAÇÃO PERDIGÃO',
//           'INOVAÇÃO SADIA',
//           'LANCHE PRONTO',
//           'LANCHES',
//           'LASANHAS',
//           'LINGUIÇA DEFUMADA',
//           'LINGUICA DEFUMADA (DOMESTICA)',
//           'LINGUICA DEFUMADA (GRANEL)',
//           'LINGUICA FRESCA (DOMESTICA)',
//           'LINGUICA FRESCA (GRANEL)',
//           'LINGUIÇA FRESCAL',
//           'LINHA DIA A DIA',
//           'MANTEIGA',
//           'MARGARINA',
//           'MARGARINA BECEL',
//           'MARGARINA CLAYBOM',
//           'MARGARINA DELINE',
//           'MARGARINA QUALY',
//           'MARGARINA SOFITELI',
//           'MIX VEGETAIS',
//           'MORTADELA MEDIA',
//           'MORTADELA MÉDIA',
//           'MORTADELA TUBULAR',
//           'OUTROS',
//           'OUTROS VEGETAIS',
//           'PAO DE QUEIJO',
//           'PATÊ',
//           'PEITO FRANGO',
//           'PEITO PERU',
//           'PEPERONI',
//           'PERU OUTROS CORTES',
//           'PIZZAS',
//           'PORCIONADOS',
//           'PRATO PRONTO',
//           'PRATOS PRONTOS',
//           'PRESUNTARIA',
//           'PRESUNTO PERU',
//           'PRESUNTO SUINO',
//           'REQUEIJAO QUALY',
//           'SALAME',
//           'SALAMITOS',
//           'SALSICHA',
//           'SALSICHA (DOMESTICA)',
//           'SALSICHA (GRANEL)',
//           'SNACK',
//           'SOBREMESA',
//           'SUBSTITUTOS CARNEOS',
//           'SUINO BARRIGA',
//           'SUINO BARRIGA SP',
//           'SUINO COSTELA SP',
//           'SUINO COSTELAS',
//           'SUINO DEFUMADO',
//           'SUINO FILE',
//           'SUINO FILE SP',
//           'SUINO LOMBO',
//           'SUINO LOMBO SP',
//           'SUINO OUTROS CORTES',
//           'SUINO PERNIL SP',
//           'SUINO SOBREPALETA',
//           'SUINO SOBREPALETA SP',
//           'TORTAS DOCES',
//           'TORTAS SALGADAS',
//         ],
//         marca: [
//           null,
//           'BECEL',
//           'CLAYBOM',
//           'DELINE',
//           'PERDIGÃO',
//           'QUALY',
//           'SADIA',
//           'SOFITELI',
//         ],
//       },
//       filtroInicial: {
//         ano: ['2023', '2022'],
//         mes: [
//           'dezembro 2023',
//           'outubro 2023',
//           'setembro 2023',
//           'agosto 2023',
//           'julho 2023',
//           'junho 2023',
//           'maio 2023',
//           'abril 2023',
//           'março 2023',
//           'fevereiro 2023',
//           'janeiro 2023',
//           'dezembro 2022',
//           'novembro 2022',
//           'outubro 2022',
//         ],
//         regional: ['NO/NE', 'SAO PAULO', 'SE/CO', 'SUL', 'TRADE COORPORATIVO'],
//         canal: [
//           'AS Nacional',
//           'C&C Nacional',
//           'C&C Regional',
//           'HSA',
//           'KAR',
//           'SEM REGRA',
//         ],
//         atividade: [
//           null,
//           'COMEMORATIVOS',
//           'CONGELADOS',
//           'EMBUTIDOS',
//           'FRIOS',
//           'IN NATURA',
//           'MARGARINAS',
//           'SOBREMESA',
//         ],
//         categoria: [
//           'AFIAMBRADO',
//           'APRESUNTADO',
//           'BACON',
//           'BACON (DOMESTICO)',
//           'BACON (GRANEL)',
//           'BATATA FRITA',
//           'BROCOLI',
//           'COMEMORATIVOS',
//           'CONGELADOS',
//           'EMPANADO (DOMESTICO)',
//           'EMPANADO (GRANEL)',
//           'EMPANADOS',
//           'ERVILHA',
//           'FATIADO ESPECIAIS',
//           'FATIADO MORTADELA MEDIA',
//           'FATIADO PEITO PERU',
//           'FATIADO PRESUNTO',
//           'FATIADO QUEIJO',
//           'FATIADO SALAME',
//           'FATIADOS',
//           'FATIADOS SOLTÍSSIMO',
//           'FRANGO ASA',
//           'FRANGO ASA SP',
//           'FRANGO CORTES ESPECIAIS',
//           'FRANGO CORTES ESPECIAIS SP',
//           'FRANGO INTEIRO BROILER SP',
//           'FRANGO MIUDOS',
//           'FRANGO MIUDOS SP',
//           'FRANGO PEITO',
//           'FRANGO PEITO SP',
//           'FRANGO PERNA',
//           'FRANGO PERNA SP',
//           'FRIOS ESPECIAIS',
//           'FRIOS LIGHT',
//           'HAMBURGUER',
//           'HAMBURGUER (DOMESTICO)',
//           'HAMBURGUER (GRANEL)',
//           'IN NATURA - SUÍNOS FÁCIL',
//           'IN NATURA-BANDEJAS',
//           'IN NATURA-CORTES DE FRANGO',
//           'IN NATURA-CORTES DE FRANGO - FOOD SERVICES',
//           'IN NATURA-FRANGO CONGELADO',
//           'IN NATURA-FRANGO FÁCIL',
//           'IN NATURA-FRANGO INTEIRO',
//           'IN NATURA-IQF',
//           'IN NATURA-NA MEDIDA',
//           'IN NATURA-PACOTE',
//           'IN NATURA-SUINO CORTES',
//           'IN NATURA-SUÍNO PERNIL',
//           'IN NATURA-SUÍNOS CONGELADOS',
//           'IN NATURA-SUÍNOS FÁCIL',
//           'IN NATURA-SUINOS OUTROS CORTES',
//           'IN NATURA-SUÍNOS SALGADOS',
//           'INOVAÇÃO PERDIGÃO',
//           'INOVAÇÃO SADIA',
//           'LANCHE PRONTO',
//           'LANCHES',
//           'LASANHAS',
//           'LINGUIÇA DEFUMADA',
//           'LINGUICA DEFUMADA (DOMESTICA)',
//           'LINGUICA DEFUMADA (GRANEL)',
//           'LINGUICA FRESCA (DOMESTICA)',
//           'LINGUICA FRESCA (GRANEL)',
//           'LINGUIÇA FRESCAL',
//           'LINHA DIA A DIA',
//           'MANTEIGA',
//           'MARGARINA',
//           'MARGARINA BECEL',
//           'MARGARINA CLAYBOM',
//           'MARGARINA DELINE',
//           'MARGARINA QUALY',
//           'MARGARINA SOFITELI',
//           'MIX VEGETAIS',
//           'MORTADELA MEDIA',
//           'MORTADELA MÉDIA',
//           'MORTADELA TUBULAR',
//           'OUTROS',
//           'OUTROS VEGETAIS',
//           'PAO DE QUEIJO',
//           'PATÊ',
//           'PEITO FRANGO',
//           'PEITO PERU',
//           'PEPERONI',
//           'PERU OUTROS CORTES',
//           'PIZZAS',
//           'PORCIONADOS',
//           'PRATO PRONTO',
//           'PRATOS PRONTOS',
//           'PRESUNTARIA',
//           'PRESUNTO PERU',
//           'PRESUNTO SUINO',
//           'REQUEIJAO QUALY',
//           'SALAME',
//           'SALAMITOS',
//           'SALSICHA',
//           'SALSICHA (DOMESTICA)',
//           'SALSICHA (GRANEL)',
//           'SNACK',
//           'SOBREMESA',
//           'SUBSTITUTOS CARNEOS',
//           'SUINO BARRIGA',
//           'SUINO BARRIGA SP',
//           'SUINO COSTELA SP',
//           'SUINO COSTELAS',
//           'SUINO DEFUMADO',
//           'SUINO FILE',
//           'SUINO FILE SP',
//           'SUINO LOMBO',
//           'SUINO LOMBO SP',
//           'SUINO OUTROS CORTES',
//           'SUINO PERNIL SP',
//           'SUINO SOBREPALETA',
//           'SUINO SOBREPALETA SP',
//           'TORTAS DOCES',
//           'TORTAS SALGADAS',
//         ],
//         marca: [
//           'BECEL',
//           'CLAYBOM',
//           'DELINE',
//           'PERDIGÃO',
//           'QUALY',
//           'SADIA',
//           'SOFITELI',
//         ],
//       },
//       filtroDisponivel: {
//         ano: ['2022', '2023'],
//         mes: [
//           'outubro 2022',
//           'novembro 2022',
//           'dezembro 2022',
//           'janeiro 2023',
//           'fevereiro 2023',
//           'março 2023',
//           'abril 2023',
//           'maio 2023',
//           'junho 2023',
//           'julho 2023',
//           'agosto 2023',
//           'setembro 2023',
//           'outubro 2023',
//           'dezembro 2023',
//         ],
//         regional: ['NO/NE', 'SAO PAULO', 'SE/CO', 'SUL', 'TRADE COORPORATIVO'],
//         canal: [
//           'AS Nacional',
//           'C&C Nacional',
//           'C&C Regional',
//           'HSA',
//           'KAR',
//           'SEM REGRA',
//         ],
//         atividade: [
//           null,
//           'COMEMORATIVOS',
//           'CONGELADOS',
//           'EMBUTIDOS',
//           'FRIOS',
//           'IN NATURA',
//           'MARGARINAS',
//           'SOBREMESA',
//         ],
//         categoria: [
//           null,
//           'AFIAMBRADO',
//           'APRESUNTADO',
//           'BACON',
//           'BACON (DOMESTICO)',
//           'BACON (GRANEL)',
//           'BATATA FRITA',
//           'BROCOLI',
//           'COMEMORATIVOS',
//           'CONGELADOS',
//           'EMPANADO (DOMESTICO)',
//           'EMPANADO (GRANEL)',
//           'EMPANADOS',
//           'ERVILHA',
//           'FATIADO ESPECIAIS',
//           'FATIADO MORTADELA MEDIA',
//           'FATIADO PEITO PERU',
//           'FATIADO PRESUNTO',
//           'FATIADO QUEIJO',
//           'FATIADO SALAME',
//           'FATIADOS',
//           'FATIADOS SOLTÍSSIMO',
//           'FRANGO ASA',
//           'FRANGO ASA SP',
//           'FRANGO CORTES ESPECIAIS',
//           'FRANGO CORTES ESPECIAIS SP',
//           'FRANGO INTEIRO BROILER SP',
//           'FRANGO MIUDOS',
//           'FRANGO MIUDOS SP',
//           'FRANGO PEITO',
//           'FRANGO PEITO SP',
//           'FRANGO PERNA',
//           'FRANGO PERNA SP',
//           'FRIOS ESPECIAIS',
//           'FRIOS LIGHT',
//           'HAMBURGUER',
//           'HAMBURGUER (DOMESTICO)',
//           'HAMBURGUER (GRANEL)',
//           'IN NATURA - SUÍNOS FÁCIL',
//           'IN NATURA-BANDEJAS',
//           'IN NATURA-CORTES DE FRANGO',
//           'IN NATURA-CORTES DE FRANGO - FOOD SERVICES',
//           'IN NATURA-FRANGO CONGELADO',
//           'IN NATURA-FRANGO FÁCIL',
//           'IN NATURA-FRANGO INTEIRO',
//           'IN NATURA-IQF',
//           'IN NATURA-NA MEDIDA',
//           'IN NATURA-PACOTE',
//           'IN NATURA-SUINO CORTES',
//           'IN NATURA-SUÍNO PERNIL',
//           'IN NATURA-SUÍNOS CONGELADOS',
//           'IN NATURA-SUÍNOS FÁCIL',
//           'IN NATURA-SUINOS OUTROS CORTES',
//           'IN NATURA-SUÍNOS SALGADOS',
//           'INOVAÇÃO PERDIGÃO',
//           'INOVAÇÃO SADIA',
//           'LANCHE PRONTO',
//           'LANCHES',
//           'LASANHAS',
//           'LINGUIÇA DEFUMADA',
//           'LINGUICA DEFUMADA (DOMESTICA)',
//           'LINGUICA DEFUMADA (GRANEL)',
//           'LINGUICA FRESCA (DOMESTICA)',
//           'LINGUICA FRESCA (GRANEL)',
//           'LINGUIÇA FRESCAL',
//           'LINHA DIA A DIA',
//           'MANTEIGA',
//           'MARGARINA',
//           'MARGARINA BECEL',
//           'MARGARINA CLAYBOM',
//           'MARGARINA DELINE',
//           'MARGARINA QUALY',
//           'MARGARINA SOFITELI',
//           'MIX VEGETAIS',
//           'MORTADELA MEDIA',
//           'MORTADELA MÉDIA',
//           'MORTADELA TUBULAR',
//           'OUTROS',
//           'OUTROS VEGETAIS',
//           'PAO DE QUEIJO',
//           'PATÊ',
//           'PEITO FRANGO',
//           'PEITO PERU',
//           'PEPERONI',
//           'PERU OUTROS CORTES',
//           'PIZZAS',
//           'PORCIONADOS',
//           'PRATO PRONTO',
//           'PRATOS PRONTOS',
//           'PRESUNTARIA',
//           'PRESUNTO PERU',
//           'PRESUNTO SUINO',
//           'REQUEIJAO QUALY',
//           'SALAME',
//           'SALAMITOS',
//           'SALSICHA',
//           'SALSICHA (DOMESTICA)',
//           'SALSICHA (GRANEL)',
//           'SNACK',
//           'SOBREMESA',
//           'SUBSTITUTOS CARNEOS',
//           'SUINO BARRIGA',
//           'SUINO BARRIGA SP',
//           'SUINO COSTELA SP',
//           'SUINO COSTELAS',
//           'SUINO DEFUMADO',
//           'SUINO FILE',
//           'SUINO FILE SP',
//           'SUINO LOMBO',
//           'SUINO LOMBO SP',
//           'SUINO OUTROS CORTES',
//           'SUINO PERNIL SP',
//           'SUINO SOBREPALETA',
//           'SUINO SOBREPALETA SP',
//           'TORTAS DOCES',
//           'TORTAS SALGADAS',
//         ],
//         marca: [
//           null,
//           'BECEL',
//           'CLAYBOM',
//           'DELINE',
//           'PERDIGÃO',
//           'QUALY',
//           'SADIA',
//           'SOFITELI',
//         ],
//       },
//     },
//     data: {
//       total: {
//         solicitacao: 2013,
//         solicitacaoAprovada: 1953,
//         totalArteAprovada: 1806,
//         qtdeImpresso: 1536000,
//         verbaUtilizada: 1366731.65,
//         verbaDisponivel: 3201485.41,
//         custoMedioImpressao: 0.27,
//         totalArteFinalizada: 0,
//         totalEncarteAprovado: 129,
//         totalEncarteImpresso: 1358000,
//         mediaImpressosEncarte: 10527,
//       },
//       produtividade: {
//         verbaBrasil: {
//           verbaDisponivel: 3201485.41,
//           verbaUtilizada: 1366731.65,
//         },
//         solicEvolucao: [
//           {
//             data: 'outubro 2022',
//             totalSolicitacao: 168,
//             totalSolicitacaoAprovada: 162,
//             totalArteAprovada: 148,
//             totalImpressoAprovado: 96000,
//           },
//           {
//             data: 'novembro 2022',
//             totalSolicitacao: 278,
//             totalSolicitacaoAprovada: 268,
//             totalArteAprovada: 245,
//             totalImpressoAprovado: 134000,
//           },
//           {
//             data: 'dezembro 2022',
//             totalSolicitacao: 157,
//             totalSolicitacaoAprovada: 153,
//             totalArteAprovada: 144,
//             totalImpressoAprovado: 63000,
//           },
//           {
//             data: 'janeiro 2023',
//             totalSolicitacao: 72,
//             totalSolicitacaoAprovada: 69,
//             totalArteAprovada: 62,
//             totalImpressoAprovado: 44000,
//           },
//           {
//             data: 'fevereiro 2023',
//             totalSolicitacao: 64,
//             totalSolicitacaoAprovada: 63,
//             totalArteAprovada: 58,
//             totalImpressoAprovado: 75000,
//           },
//           {
//             data: 'março 2023',
//             totalSolicitacao: 152,
//             totalSolicitacaoAprovada: 147,
//             totalArteAprovada: 133,
//             totalImpressoAprovado: 183000,
//           },
//           {
//             data: 'abril 2023',
//             totalSolicitacao: 148,
//             totalSolicitacaoAprovada: 142,
//             totalArteAprovada: 133,
//             totalImpressoAprovado: 203000,
//           },
//           {
//             data: 'maio 2023',
//             totalSolicitacao: 182,
//             totalSolicitacaoAprovada: 175,
//             totalArteAprovada: 172,
//             totalImpressoAprovado: 199000,
//           },
//           {
//             data: 'junho 2023',
//             totalSolicitacao: 220,
//             totalSolicitacaoAprovada: 217,
//             totalArteAprovada: 204,
//             totalImpressoAprovado: 171000,
//           },
//           {
//             data: 'julho 2023',
//             totalSolicitacao: 191,
//             totalSolicitacaoAprovada: 184,
//             totalArteAprovada: 169,
//             totalImpressoAprovado: 144000,
//           },
//           {
//             data: 'agosto 2023',
//             totalSolicitacao: 175,
//             totalSolicitacaoAprovada: 173,
//             totalArteAprovada: 161,
//             totalImpressoAprovado: 204000,
//           },
//           {
//             data: 'setembro 2023',
//             totalSolicitacao: 97,
//             totalSolicitacaoAprovada: 97,
//             totalArteAprovada: 94,
//             totalImpressoAprovado: 105000,
//           },
//           {
//             data: 'outubro 2023',
//             totalSolicitacao: 108,
//             totalSolicitacaoAprovada: 103,
//             totalArteAprovada: 83,
//             totalImpressoAprovado: 176000,
//           },
//           {
//             data: 'dezembro 2023',
//             totalSolicitacao: 1,
//             totalSolicitacaoAprovada: 0,
//             totalArteAprovada: 0,
//             totalImpressoAprovado: 0,
//           },
//         ],
//         solicUF: [
//           {
//             nome: 'SP',
//             uf: null,
//             total: 645,
//             totalAprovado: 0,
//             porcentagem: 32.04,
//           },
//           {
//             nome: 'RJ',
//             uf: null,
//             total: 306,
//             totalAprovado: 0,
//             porcentagem: 15.2,
//           },
//           {
//             nome: 'MG',
//             uf: null,
//             total: 267,
//             totalAprovado: 0,
//             porcentagem: 13.26,
//           },
//           {
//             nome: 'SC',
//             uf: null,
//             total: 137,
//             totalAprovado: 0,
//             porcentagem: 6.81,
//           },
//           {
//             nome: 'PR',
//             uf: null,
//             total: 128,
//             totalAprovado: 0,
//             porcentagem: 6.36,
//           },
//           {
//             nome: 'BA',
//             uf: null,
//             total: 79,
//             totalAprovado: 0,
//             porcentagem: 3.92,
//           },
//           {
//             nome: 'PA',
//             uf: null,
//             total: 73,
//             totalAprovado: 0,
//             porcentagem: 3.63,
//           },
//           {
//             nome: 'DF',
//             uf: null,
//             total: 69,
//             totalAprovado: 0,
//             porcentagem: 3.43,
//           },
//           {
//             nome: 'CE',
//             uf: null,
//             total: 64,
//             totalAprovado: 0,
//             porcentagem: 3.18,
//           },
//           {
//             nome: 'PE',
//             uf: null,
//             total: 46,
//             totalAprovado: 0,
//             porcentagem: 2.29,
//           },
//           {
//             nome: 'AM',
//             uf: null,
//             total: 36,
//             totalAprovado: 0,
//             porcentagem: 1.79,
//           },
//           {
//             nome: 'MS',
//             uf: null,
//             total: 31,
//             totalAprovado: 0,
//             porcentagem: 1.54,
//           },
//           {
//             nome: 'ES',
//             uf: null,
//             total: 26,
//             totalAprovado: 0,
//             porcentagem: 1.29,
//           },
//           {
//             nome: 'RS',
//             uf: null,
//             total: 19,
//             totalAprovado: 0,
//             porcentagem: 0.94,
//           },
//           {
//             nome: 'MT',
//             uf: null,
//             total: 16,
//             totalAprovado: 0,
//             porcentagem: 0.79,
//           },
//           {
//             nome: 'AP',
//             uf: null,
//             total: 14,
//             totalAprovado: 0,
//             porcentagem: 0.7,
//           },
//           {
//             nome: 'AL',
//             uf: null,
//             total: 12,
//             totalAprovado: 0,
//             porcentagem: 0.6,
//           },
//           {
//             nome: 'SE',
//             uf: null,
//             total: 12,
//             totalAprovado: 0,
//             porcentagem: 0.6,
//           },
//           {
//             nome: 'RR',
//             uf: null,
//             total: 12,
//             totalAprovado: 0,
//             porcentagem: 0.6,
//           },
//           {
//             nome: 'MA',
//             uf: null,
//             total: 9,
//             totalAprovado: 0,
//             porcentagem: 0.45,
//           },
//           {
//             nome: 'PI',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0.25,
//           },
//           {
//             nome: 'RO',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0.15,
//           },
//           {
//             nome: 'GO',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0.1,
//           },
//           {
//             nome: 'RN',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0.1,
//           },
//         ],
//         solicCidade: [
//           {
//             nome: 'Rio de Janeiro',
//             uf: null,
//             total: 239,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Paulo',
//             uf: null,
//             total: 192,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Contagem',
//             uf: null,
//             total: 90,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Curitiba',
//             uf: null,
//             total: 76,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sumare',
//             uf: null,
//             total: 60,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Salvador',
//             uf: null,
//             total: 60,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Belem',
//             uf: null,
//             total: 58,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Juiz de Fora',
//             uf: null,
//             total: 50,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Jandira',
//             uf: null,
//             total: 47,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Guarulhos',
//             uf: null,
//             total: 44,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Brasilia',
//             uf: null,
//             total: 41,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Nova Lima',
//             uf: null,
//             total: 36,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Manaus',
//             uf: null,
//             total: 36,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Biguacu',
//             uf: null,
//             total: 34,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Ribeirao das Neves',
//             uf: null,
//             total: 34,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Jose',
//             uf: null,
//             total: 34,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Fortaleza',
//             uf: null,
//             total: 33,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Tijucas',
//             uf: null,
//             total: 33,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Campo Grande',
//             uf: null,
//             total: 32,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Campinas',
//             uf: null,
//             total: 32,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Suzano',
//             uf: null,
//             total: 31,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Maracanau',
//             uf: null,
//             total: 29,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Taguatinga',
//             uf: null,
//             total: 27,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Aracatuba',
//             uf: null,
//             total: 25,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Bauru',
//             uf: null,
//             total: 20,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Divinopolis',
//             uf: null,
//             total: 19,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Valinhos',
//             uf: null,
//             total: 19,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Jundiai',
//             uf: null,
//             total: 18,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Caucaia',
//             uf: null,
//             total: 16,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Icara',
//             uf: null,
//             total: 15,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Itajai',
//             uf: null,
//             total: 15,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Jau',
//             uf: null,
//             total: 14,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Belo Horizonte',
//             uf: null,
//             total: 14,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Paicandu',
//             uf: null,
//             total: 14,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Boa Vista',
//             uf: null,
//             total: 13,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Piracicaba',
//             uf: null,
//             total: 13,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Itapevi',
//             uf: null,
//             total: 12,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Varzea Grande',
//             uf: null,
//             total: 12,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Jose dos Campos',
//             uf: null,
//             total: 12,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Angra dos Reis',
//             uf: null,
//             total: 11,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Aracaju',
//             uf: null,
//             total: 10,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Roque',
//             uf: null,
//             total: 10,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Jose do Rio Preto',
//             uf: null,
//             total: 10,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Palmares do Sul',
//             uf: null,
//             total: 9,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Barra Mansa',
//             uf: null,
//             total: 9,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Americana',
//             uf: null,
//             total: 9,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Jaboatao dos Guararapes',
//             uf: null,
//             total: 9,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Campos dos Goytacazes',
//             uf: null,
//             total: 8,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Ananindeua',
//             uf: null,
//             total: 8,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Vitoria',
//             uf: null,
//             total: 8,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Betim',
//             uf: null,
//             total: 8,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Serra',
//             uf: null,
//             total: 8,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Maringa',
//             uf: null,
//             total: 8,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Lauro de Freitas',
//             uf: null,
//             total: 8,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Palhoca',
//             uf: null,
//             total: 7,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Tatui',
//             uf: null,
//             total: 7,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Parnamirim',
//             uf: null,
//             total: 7,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Nova Odessa',
//             uf: null,
//             total: 7,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Luis',
//             uf: null,
//             total: 7,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Ribeirao Preto',
//             uf: null,
//             total: 7,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Recife',
//             uf: null,
//             total: 7,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LUIS EDUARDO MAGALHAES',
//             uf: null,
//             total: 6,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Itaborai',
//             uf: null,
//             total: 6,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Garca',
//             uf: null,
//             total: 6,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Teresina',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Governador Valadares',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Marilia',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Nova Iguacu',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Duque de Caxias',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Joao de Meriti',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Niteroi',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Criciuma',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Maceio',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Carpina',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Ipojuca',
//             uf: null,
//             total: 5,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Florianopolis',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Cascavel',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Linhares',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Vila Velha',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Cuiaba',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sertaozinho',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Itaquaquecetuba',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Cabo de Santo Agostinho',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Petrolina',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Feira de Santana',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Petropolis',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Bocaiuva',
//             uf: null,
//             total: 4,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Porto Velho',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Concordia',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Castanhal',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Limeira',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Mogi das Cruzes',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Canoas',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Porto Alegre',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sorocaba',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Maraba',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Pereira Barreto',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Votuporanga',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Barretos',
//             uf: null,
//             total: 3,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Lajeado',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Pirajui',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Aruja',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Abaetetuba',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Marituba',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Hortolandia',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Jaboticabal',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Caruaru',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Cosmopolis',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Barueri',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Cachoeirinha',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Birigui',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Volta Redonda',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Alvorada',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Nossa Senhora do Socorro',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Leme',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Tres Rios',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Natal',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Garanhuns',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Londrina',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Paty do Alferes',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Jose de Ribamar',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Matao',
//             uf: null,
//             total: 2,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Guarapari',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Cariacica',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Tailandia',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Carapicuiba',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Videira',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Braganca Paulista',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Ceilandia',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Barra Velha',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Goncalo',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Pinhalzinho',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Gravata',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Indaiatuba',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Anapolis',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Janauba',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Mogi-mirim',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Nova Friburgo',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Miguel Pereira',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Pinhais',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Parauapebas',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Barbacena',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Araras',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Ilheus',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Curvelo',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Araraquara',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Goiania',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Sao Carlos',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Francisco Morato',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Candido Mota',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Taubate',
//             uf: null,
//             total: 1,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//         ],
//         solicRede: [
//           {
//             nome: 'INSTITUCIONAL SE',
//             uf: null,
//             total: 103,
//             totalAprovado: 102,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CENCOSUD',
//             uf: null,
//             total: 95,
//             totalAprovado: 91,
//             porcentagem: 0,
//           },
//           {
//             nome: 'REDE COMPER',
//             uf: null,
//             total: 89,
//             totalAprovado: 85,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUPERNOSSO APOIO',
//             uf: null,
//             total: 66,
//             totalAprovado: 65,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUPERMARKET',
//             uf: null,
//             total: 57,
//             totalAprovado: 56,
//             porcentagem: 0,
//           },
//           {
//             nome: 'COVABRA',
//             uf: null,
//             total: 51,
//             totalAprovado: 51,
//             porcentagem: 0,
//           },
//           {
//             nome: 'BAHAMAS',
//             uf: null,
//             total: 49,
//             totalAprovado: 49,
//             porcentagem: 0,
//           },
//           {
//             nome: 'INSTITUCIONAL SP',
//             uf: null,
//             total: 48,
//             totalAprovado: 44,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MAMBO',
//             uf: null,
//             total: 47,
//             totalAprovado: 45,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FESTIVAL',
//             uf: null,
//             total: 46,
//             totalAprovado: 41,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ASSAI',
//             uf: null,
//             total: 37,
//             totalAprovado: 37,
//             porcentagem: 0,
//           },
//           {
//             nome: 'VERDEMAR',
//             uf: null,
//             total: 36,
//             totalAprovado: 36,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MATEUS',
//             uf: null,
//             total: 35,
//             totalAprovado: 33,
//             porcentagem: 0,
//           },
//           {
//             nome: 'DALBEN',
//             uf: null,
//             total: 35,
//             totalAprovado: 34,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SEMAR',
//             uf: null,
//             total: 34,
//             totalAprovado: 33,
//             porcentagem: 0,
//           },
//           {
//             nome: 'BISTEK',
//             uf: null,
//             total: 34,
//             totalAprovado: 31,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LIDER',
//             uf: null,
//             total: 34,
//             totalAprovado: 34,
//             porcentagem: 0,
//           },
//           {
//             nome: 'KOCH',
//             uf: null,
//             total: 33,
//             totalAprovado: 33,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ANDORINHA',
//             uf: null,
//             total: 33,
//             totalAprovado: 33,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SAVEGNAGO',
//             uf: null,
//             total: 32,
//             totalAprovado: 32,
//             porcentagem: 0,
//           },
//           {
//             nome: 'REDE BH',
//             uf: null,
//             total: 30,
//             totalAprovado: 29,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MUNDIAL',
//             uf: null,
//             total: 27,
//             totalAprovado: 27,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FORMOSA',
//             uf: null,
//             total: 26,
//             totalAprovado: 26,
//             porcentagem: 0,
//           },
//           {
//             nome: 'NOVA ERA',
//             uf: null,
//             total: 26,
//             totalAprovado: 25,
//             porcentagem: 0,
//           },
//           {
//             nome: 'INSTITUCIONAL CO',
//             uf: null,
//             total: 26,
//             totalAprovado: 25,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUP RONDON',
//             uf: null,
//             total: 25,
//             totalAprovado: 25,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CONDOR',
//             uf: null,
//             total: 24,
//             totalAprovado: 24,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CEMA',
//             uf: null,
//             total: 24,
//             totalAprovado: 24,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CARREFOUR',
//             uf: null,
//             total: 22,
//             totalAprovado: 22,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUPER A FRANGOLANDIA',
//             uf: null,
//             total: 22,
//             totalAprovado: 22,
//             porcentagem: 0,
//           },
//           {
//             nome: 'JAD ZOGHEIB',
//             uf: null,
//             total: 21,
//             totalAprovado: 19,
//             porcentagem: 0,
//           },
//           {
//             nome: 'DB',
//             uf: null,
//             total: 21,
//             totalAprovado: 19,
//             porcentagem: 0,
//           },
//           {
//             nome: 'BIG BOX',
//             uf: null,
//             total: 20,
//             totalAprovado: 18,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PAGUE MENOS',
//             uf: null,
//             total: 20,
//             totalAprovado: 20,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ABC',
//             uf: null,
//             total: 19,
//             totalAprovado: 19,
//             porcentagem: 0,
//           },
//           {
//             nome: 'GIASSI',
//             uf: null,
//             total: 18,
//             totalAprovado: 18,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ZONA SUL',
//             uf: null,
//             total: 18,
//             totalAprovado: 17,
//             porcentagem: 0,
//           },
//           {
//             nome: 'JMELO',
//             uf: null,
//             total: 18,
//             totalAprovado: 18,
//             porcentagem: 0,
//           },
//           {
//             nome: 'GPA',
//             uf: null,
//             total: 18,
//             totalAprovado: 17,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FAMILIA',
//             uf: null,
//             total: 18,
//             totalAprovado: 18,
//             porcentagem: 0,
//           },
//         ],
//         solicSolicitante: [
//           {
//             nome: 'CLARICE RANGEL DE ALMEIDA BERTOLINI',
//             uf: null,
//             total: 124,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'BRUNA ELIZABETH',
//             uf: null,
//             total: 79,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARIANA VITORIA DO PRADO BARBOSA',
//             uf: null,
//             total: 76,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'THIAGO ROCHA',
//             uf: null,
//             total: 70,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ALVARO SEABRA',
//             uf: null,
//             total: 70,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FERNANDO CURBAN CROCCO',
//             uf: null,
//             total: 61,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'EMERSON ROLDAO DE JESUS',
//             uf: null,
//             total: 49,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'YGOR BERRIEL DUARTE',
//             uf: null,
//             total: 47,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'HALLEY DE MORAIS SILVA',
//             uf: null,
//             total: 45,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CLOVIANE CAMARGO DE ABREU SANTUCCI',
//             uf: null,
//             total: 42,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARCOS VINCO ',
//             uf: null,
//             total: 41,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'TIAGO MENDES',
//             uf: null,
//             total: 41,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ALEX MESSIAS',
//             uf: null,
//             total: 40,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MILENA XIMENES',
//             uf: null,
//             total: 35,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CAMILA PAVAN TOZELLI',
//             uf: null,
//             total: 35,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'JOÃO PEDRO MOTTA LOURENÇO',
//             uf: null,
//             total: 34,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'DEBORAH DA SILVEIRA OLIVEIRA',
//             uf: null,
//             total: 32,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'GLAUCE DA SILVA BARBARA',
//             uf: null,
//             total: 31,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ROBSON TADEU MARQUES VAZ',
//             uf: null,
//             total: 31,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'HEBERSON BARBOZA DE SOUZA',
//             uf: null,
//             total: 31,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LOIR CAMILO MAURINA',
//             uf: null,
//             total: 30,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'EMERSON SOUZA PEREIRA',
//             uf: null,
//             total: 30,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'JUNI USIEL REICHERT DA SILVA',
//             uf: null,
//             total: 30,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'RENATA VICTORIA DE SOUZA BERNARDO',
//             uf: null,
//             total: 29,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'WAGNER BAIO',
//             uf: null,
//             total: 28,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ESTER RODRIGUES CAVALCANTI',
//             uf: null,
//             total: 28,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'WILLIAM TERTULIANO DAGOSTIN',
//             uf: null,
//             total: 27,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'THAYS TALYTA PEREIRA',
//             uf: null,
//             total: 27,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'JONATHAN CARVALHO DA SILVA',
//             uf: null,
//             total: 27,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'RODRIGO RAIMUNDI GOUVEA',
//             uf: null,
//             total: 26,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'KAREN MARTIN BRANDAO',
//             uf: null,
//             total: 25,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'GABRIEL FERREIRA BRAGA',
//             uf: null,
//             total: 24,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MAXWEL PINHEIRO FERRAZ',
//             uf: null,
//             total: 24,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CAMILA ESTEFANATI',
//             uf: null,
//             total: 20,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'JOSEANA ARANTES PEREIRA',
//             uf: null,
//             total: 20,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CAMILA LEIBEL',
//             uf: null,
//             total: 20,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CRISTIANE DAL COL',
//             uf: null,
//             total: 19,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARCELO TRARBACH RODRIGUES',
//             uf: null,
//             total: 17,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LUCINEIA BATISTA DOS SANTOS',
//             uf: null,
//             total: 17,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MÔNICA RICARDO DE VASCONCELOS ANDRADE',
//             uf: null,
//             total: 17,
//             totalAprovado: 0,
//             porcentagem: 0,
//           },
//         ],
//         solicMaterial: [
//           {
//             nome: 'ENCARTE FRENTE E VERSO',
//             uf: null,
//             total: 352,
//             totalAprovado: 347,
//             porcentagem: 17.49,
//           },
//           {
//             nome: 'BANNER ESTÁTICO P/ SITE',
//             uf: null,
//             total: 345,
//             totalAprovado: 339,
//             porcentagem: 17.14,
//           },
//           {
//             nome: 'POST REDES SOCIAIS',
//             uf: null,
//             total: 289,
//             totalAprovado: 284,
//             porcentagem: 14.36,
//           },
//           {
//             nome: 'BOX PROMOCIONAL',
//             uf: null,
//             total: 259,
//             totalAprovado: 255,
//             porcentagem: 12.87,
//           },
//           {
//             nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//             uf: null,
//             total: 168,
//             totalAprovado: 164,
//             porcentagem: 8.35,
//           },
//           {
//             nome: 'LÂMINA',
//             uf: null,
//             total: 145,
//             totalAprovado: 136,
//             porcentagem: 7.2,
//           },
//           {
//             nome: 'BOX MEIA PAGINA',
//             uf: null,
//             total: 58,
//             totalAprovado: 55,
//             porcentagem: 2.88,
//           },
//           {
//             nome: 'VIDEO',
//             uf: null,
//             total: 58,
//             totalAprovado: 57,
//             porcentagem: 2.88,
//           },
//           {
//             nome: 'ADESIVO',
//             uf: null,
//             total: 55,
//             totalAprovado: 51,
//             porcentagem: 2.73,
//           },
//           {
//             nome: 'BANNER FÍSICO',
//             uf: null,
//             total: 53,
//             totalAprovado: 45,
//             porcentagem: 2.63,
//           },
//           {
//             nome: 'ADESIVO P/ FREEZER',
//             uf: null,
//             total: 41,
//             totalAprovado: 41,
//             porcentagem: 2.04,
//           },
//           {
//             nome: 'EMAIL MKT',
//             uf: null,
//             total: 33,
//             totalAprovado: 33,
//             porcentagem: 1.64,
//           },
//           {
//             nome: 'CARTAZ',
//             uf: null,
//             total: 32,
//             totalAprovado: 30,
//             porcentagem: 1.59,
//           },
//           {
//             nome: 'BANNER ANIMADO P/ SITE',
//             uf: null,
//             total: 28,
//             totalAprovado: 26,
//             porcentagem: 1.39,
//           },
//           {
//             nome: 'BACKLIGHT',
//             uf: null,
//             total: 26,
//             totalAprovado: 26,
//             porcentagem: 1.29,
//           },
//           {
//             nome: 'TABLE TENT',
//             uf: null,
//             total: 18,
//             totalAprovado: 18,
//             porcentagem: 0.89,
//           },
//           {
//             nome: 'VIDEO ATÉ 30S',
//             uf: null,
//             total: 13,
//             totalAprovado: 13,
//             porcentagem: 0.65,
//           },
//           {
//             nome: 'TOTEM',
//             uf: null,
//             total: 11,
//             totalAprovado: 10,
//             porcentagem: 0.55,
//           },
//           {
//             nome: 'ENCARTE 4 PÁGINAS',
//             uf: null,
//             total: 9,
//             totalAprovado: 4,
//             porcentagem: 0.45,
//           },
//           {
//             nome: 'FLYER',
//             uf: null,
//             total: 7,
//             totalAprovado: 7,
//             porcentagem: 0.35,
//           },
//           {
//             nome: 'JOGO AMERICANO',
//             uf: null,
//             total: 5,
//             totalAprovado: 5,
//             porcentagem: 0.25,
//           },
//           {
//             nome: 'WOBBLER',
//             uf: null,
//             total: 2,
//             totalAprovado: 1,
//             porcentagem: 0.1,
//           },
//           {
//             nome: 'SPLASH',
//             uf: null,
//             total: 2,
//             totalAprovado: 2,
//             porcentagem: 0.1,
//           },
//           {
//             nome: 'SPOT DE RÁDIO (*)',
//             uf: null,
//             total: 2,
//             totalAprovado: 2,
//             porcentagem: 0.1,
//           },
//           {
//             nome: 'CATÁLOGO',
//             uf: null,
//             total: 1,
//             totalAprovado: 1,
//             porcentagem: 0.05,
//           },
//           {
//             nome: 'STOPPER',
//             uf: null,
//             total: 1,
//             totalAprovado: 1,
//             porcentagem: 0.05,
//           },
//         ],
//         solicRegional: [
//           {
//             nome: 'SE/CO',
//             uf: null,
//             total: 720,
//             totalAprovado: 701,
//             porcentagem: 35.77,
//           },
//           {
//             nome: 'SAO PAULO',
//             uf: null,
//             total: 628,
//             totalAprovado: 609,
//             porcentagem: 31.2,
//           },
//           {
//             nome: 'NO/NE',
//             uf: null,
//             total: 364,
//             totalAprovado: 353,
//             porcentagem: 18.08,
//           },
//           {
//             nome: 'SUL',
//             uf: null,
//             total: 284,
//             totalAprovado: 273,
//             porcentagem: 14.11,
//           },
//           {
//             nome: 'TRADE COORPORATIVO',
//             uf: null,
//             total: 17,
//             totalAprovado: 17,
//             porcentagem: 0.84,
//           },
//         ],
//         solicCanal: [
//           {
//             nome: 'HSA',
//             uf: null,
//             total: 1220,
//             totalAprovado: 1182,
//             porcentagem: 60.61,
//           },
//           {
//             nome: 'C&C Regional',
//             uf: null,
//             total: 410,
//             totalAprovado: 396,
//             porcentagem: 20.37,
//           },
//           {
//             nome: 'SEM REGRA',
//             uf: null,
//             total: 202,
//             totalAprovado: 196,
//             porcentagem: 10.03,
//           },
//           {
//             nome: 'KAR',
//             uf: null,
//             total: 84,
//             totalAprovado: 84,
//             porcentagem: 4.17,
//           },
//           {
//             nome: 'C&C Nacional',
//             uf: null,
//             total: 56,
//             totalAprovado: 55,
//             porcentagem: 2.78,
//           },
//           {
//             nome: 'AS Nacional',
//             uf: null,
//             total: 41,
//             totalAprovado: 40,
//             porcentagem: 2.04,
//           },
//         ],
//       },
//       custo: {
//         verbaBrasil: {
//           verbaDisponivel: 3201485.41,
//           verbaUtilizada: 1366731.65,
//         },
//         verbaRegional: [
//           {
//             nome: 'TRADE COORPORATIVO',
//             mes: null,
//             ano: 0,
//             totalCusto: 18403,
//             totalVerba: 159412,
//             porcentagem: 11,
//             custoMedio: 0,
//             regional: null,
//             material: null,
//           },
//           {
//             nome: 'SUL',
//             mes: null,
//             ano: 0,
//             totalCusto: 114565,
//             totalVerba: 168963,
//             porcentagem: 67,
//             custoMedio: 0,
//             regional: null,
//             material: null,
//           },
//           {
//             nome: 'SE/CO',
//             mes: null,
//             ano: 0,
//             totalCusto: 518648,
//             totalVerba: 392776,
//             porcentagem: 132,
//             custoMedio: 0,
//             regional: null,
//             material: null,
//           },
//           {
//             nome: 'SAO PAULO',
//             mes: null,
//             ano: 0,
//             totalCusto: 599924,
//             totalVerba: 1610769,
//             porcentagem: 37,
//             custoMedio: 0,
//             regional: null,
//             material: null,
//           },
//           {
//             nome: 'NO/NE',
//             mes: null,
//             ano: 0,
//             totalCusto: 162855,
//             totalVerba: 126331,
//             porcentagem: 128,
//             custoMedio: 0,
//             regional: null,
//             material: null,
//           },
//         ],
//         verbaDisponivel: [],
//         investimentoTotalMensal: [
//           {
//             ano: '2022',
//             data: [
//               {
//                 nome: 'outubro 2022',
//                 mes: 'outubro',
//                 ano: 2022,
//                 totalCusto: 78402,
//                 totalVerba: 630000,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'novembro 2022',
//                 mes: 'novembro',
//                 ano: 2022,
//                 totalCusto: 150002,
//                 totalVerba: 552917,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'dezembro 2022',
//                 mes: 'dezembro',
//                 ano: 2022,
//                 totalCusto: 76776,
//                 totalVerba: 410483,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//             ],
//           },
//           {
//             ano: '2023',
//             data: [
//               {
//                 nome: 'janeiro 2023',
//                 mes: 'janeiro',
//                 ano: 2023,
//                 totalCusto: 39764,
//                 totalVerba: 40466,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'fevereiro 2023',
//                 mes: 'fevereiro',
//                 ano: 2023,
//                 totalCusto: 46946,
//                 totalVerba: 46473,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'março 2023',
//                 mes: 'março',
//                 ano: 2023,
//                 totalCusto: 77481,
//                 totalVerba: 119870,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'abril 2023',
//                 mes: 'abril',
//                 ano: 2023,
//                 totalCusto: 106321,
//                 totalVerba: 107760,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'maio 2023',
//                 mes: 'maio',
//                 ano: 2023,
//                 totalCusto: 110612,
//                 totalVerba: 137181,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'junho 2023',
//                 mes: 'junho',
//                 ano: 2023,
//                 totalCusto: 164711,
//                 totalVerba: 240438,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'julho 2023',
//                 mes: 'julho',
//                 ano: 2023,
//                 totalCusto: 138407,
//                 totalVerba: 154733,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'agosto 2023',
//                 mes: 'agosto',
//                 ano: 2023,
//                 totalCusto: 172640,
//                 totalVerba: 172640,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'setembro 2023',
//                 mes: 'setembro',
//                 ano: 2023,
//                 totalCusto: 75103,
//                 totalVerba: 97919,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'outubro 2023',
//                 mes: 'outubro',
//                 ano: 2023,
//                 totalCusto: 103852,
//                 totalVerba: 490600,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'dezembro 2023',
//                 mes: 'dezembro',
//                 ano: 2023,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//             ],
//           },
//         ],
//         investimentoTotalRegional: [
//           {
//             ano: '2022',
//             data: [
//               {
//                 nome: 'NO/NE',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 39687,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 82386,
//                 totalVerba: 925769,
//                 porcentagem: 8,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'SE/CO',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 138682,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'SUL',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 44425,
//                 totalVerba: 61763,
//                 porcentagem: 71,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//             ],
//           },
//           {
//             ano: '2023',
//             data: [
//               {
//                 nome: 'NO/NE',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 122510,
//                 totalVerba: 126331,
//                 porcentagem: 96,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 509668,
//                 totalVerba: 685000,
//                 porcentagem: 74,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'SE/CO',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 318187,
//                 totalVerba: 392776,
//                 porcentagem: 81,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'SUL',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 68317,
//                 totalVerba: 107200,
//                 porcentagem: 63,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 17158,
//                 totalVerba: 89412,
//                 porcentagem: 19,
//                 custoMedio: 0,
//                 regional: null,
//                 material: null,
//               },
//             ],
//           },
//         ],
//         custoMedioSolicitacaoMensal: [
//           {
//             ano: '2022',
//             data: [
//               {
//                 nome: 'outubro - 2022',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'outubro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 358,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'outubro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 440,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'outubro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 557,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'outubro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 539,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'novembro - 2022',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'novembro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 541,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'novembro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 438,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'novembro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 588,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'novembro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 626,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'dezembro - 2022',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'dezembro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 346,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'dezembro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 809,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'dezembro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 441,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'dezembro - 2022',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 318,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//             ],
//           },
//           {
//             ano: '2023',
//             data: [
//               {
//                 nome: 'janeiro - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'janeiro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 459,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'janeiro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 672,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'janeiro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 432,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'janeiro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 344,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'fevereiro - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'fevereiro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 822,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'fevereiro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 1052,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'fevereiro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 759,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'fevereiro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 450,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'março - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'março - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 262,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'março - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 744,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'março - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 492,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'março - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 322,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TRADE COORPORATIVO',
//                     mes: 'março - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 592,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'abril - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'abril - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 358,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'abril - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 989,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'abril - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 610,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'abril - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 404,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TRADE COORPORATIVO',
//                     mes: 'abril - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 538,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'maio - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'maio - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 387,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'maio - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 1110,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'maio - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 398,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'maio - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 270,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'junho - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'junho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 528,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'junho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 1330,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'junho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 494,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'junho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 301,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TRADE COORPORATIVO',
//                     mes: 'junho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 1244,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'julho - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'julho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 519,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'julho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 868,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'julho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 916,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'julho - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 349,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'agosto - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'agosto - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 435,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'agosto - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 1457,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'agosto - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 996,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'agosto - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 351,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TRADE COORPORATIVO',
//                     mes: 'agosto - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 984,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'setembro - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'setembro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 325,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'setembro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 764,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'setembro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 1001,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'setembro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 389,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TRADE COORPORATIVO',
//                     mes: 'setembro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 1638,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'outubro - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'outubro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 626,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SAO PAULO',
//                     mes: 'outubro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 1633,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SE/CO',
//                     mes: 'outubro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 521,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SUL',
//                     mes: 'outubro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 340,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TRADE COORPORATIVO',
//                     mes: 'outubro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//               {
//                 nome: 'dezembro - 2023',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: [
//                   {
//                     nome: 'NO/NE',
//                     mes: 'dezembro - 2023',
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//                 material: null,
//               },
//             ],
//           },
//         ],
//         investimentoRegional: [
//           {
//             nome: 'SAO PAULO',
//             custoDiagramacao: 238977.86,
//             custoImpressao: 274651.99,
//             custoFrete: 86294.5,
//             custoMedio: 0,
//             totalCusto: 599924.35,
//             totalVerba: 1610769.41,
//             totalSolicitacoes: 628,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 0,
//           },
//           {
//             nome: 'SE/CO',
//             custoDiagramacao: 310736.81,
//             custoImpressao: 135577.56,
//             custoFrete: 72334.36,
//             custoMedio: 0,
//             totalCusto: 518648.73,
//             totalVerba: 392776.6,
//             totalSolicitacoes: 720,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 0,
//           },
//           {
//             nome: 'NO/NE',
//             custoDiagramacao: 147328.98,
//             custoImpressao: 8567.12,
//             custoFrete: 6959.43,
//             custoMedio: 0,
//             totalCusto: 162855.53,
//             totalVerba: 126331.42,
//             totalSolicitacoes: 364,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 0,
//           },
//           {
//             nome: 'SUL',
//             custoDiagramacao: 113153.39,
//             custoImpressao: 1048.4,
//             custoFrete: 364.1,
//             custoMedio: 0,
//             totalCusto: 114565.89,
//             totalVerba: 168963.04,
//             totalSolicitacoes: 284,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 0,
//           },
//           {
//             nome: 'TRADE COORPORATIVO',
//             custoDiagramacao: 18403.8,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 18403.8,
//             totalVerba: 159412.4,
//             totalSolicitacoes: 17,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0,
//           },
//         ],
//         investimentoMaterialMensal: [
//           {
//             ano: '2022',
//             data: [
//               {
//                 nome: 'outubro',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 630000,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1147,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BACKLIGHT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2808,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1785,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 17127,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 279,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX MEIA PAGINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2854,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2088,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2581,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2295,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE 4 PÁGINAS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 144,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 25445,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3820,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 7483,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 7667,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TOTEM',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 777,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'WOBBLER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 96,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'novembro',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 552917,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 5261,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6994,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BACKLIGHT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4228,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2040,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 20655,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3479,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX MEIA PAGINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3128,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4078,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6585,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 807,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE 4 PÁGINAS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3789,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 30203,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'FLYER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1632,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'JOGO AMERICANO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1618,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3015,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 13661,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 23364,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SPLASH',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 585,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TABLE TENT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2030,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TOTEM',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2352,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 7770,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO ATÉ 30S',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2719,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'WOBBLER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'dezembro',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 410483,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2295,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1551,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BACKLIGHT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 859,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2295,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 8755,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 636,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX MEIA PAGINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2622,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1776,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1122,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CATÁLOGO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 921,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 595,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 22350,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2093,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 8821,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 15359,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TABLE TENT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 966,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TOTEM',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3755,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//             ],
//           },
//           {
//             ano: '2023',
//             data: [
//               {
//                 nome: 'janeiro',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 40466,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 286,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 620,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BACKLIGHT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4018,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 9520,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX MEIA PAGINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2392,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 740,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 374,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 425,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE 4 PÁGINAS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 10355,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1512,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2617,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3017,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TABLE TENT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 775,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2331,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO ATÉ 30S',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 777,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'fevereiro',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 46473,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1933,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1020,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3952,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3608,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 425,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 27143,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 739,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4129,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3995,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'março',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 119870,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3733,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 644,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6417,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 5940,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1190,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 37488,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1637,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 14209,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4666,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1554,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'abril',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 107760,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2888,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 8525,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1978,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6008,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 823,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 297,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE 4 PÁGINAS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 58691,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'JOGO AMERICANO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 170,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 5935,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6564,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 8644,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TABLE TENT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 483,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TOTEM',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 648,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3108,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO ATÉ 30S',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1554,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'maio',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 137181,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1818,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 310,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 510,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 12214,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 193,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 8728,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2993,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2040,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 58156,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2535,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2896,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 13812,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4403,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'junho',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 240438,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3646,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6097,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2091,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 16880,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4583,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 10140,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 876,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1593,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 82284,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'FLYER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 922,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'JOGO AMERICANO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1367,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3998,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 10201,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 10176,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TABLE TENT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 5506,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO ATÉ 30S',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4344,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'julho',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 154733,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 445,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 9755,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1195,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 16470,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3256,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 10218,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 61734,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3830,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 17175,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6025,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'STOPPER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 621,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TOTEM',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1140,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 5090,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO ATÉ 30S',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1448,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'agosto',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 172640,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3197,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1524,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 16633,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 931,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 8536,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1753,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1244,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 91847,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4089,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 13718,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 8665,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'SPOT DE RÁDIO (*)',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1800,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TOTEM',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 380,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 16506,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO ATÉ 30S',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1810,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'setembro',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 97919,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1449,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 609,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6324,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 185,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 7486,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4294,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2104,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 38609,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1768,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 3734,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2838,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 5697,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'outubro',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 490600,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ADESIVO P/ FREEZER',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 5792,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ANIMADO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER ESTÁTICO P/ SITE',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 5826,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BANNER FÍSICO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4576,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'BOX PROMOCIONAL',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 7172,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'CARTAZ',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1315,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'EMAIL MKT',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'ENCARTE FRENTE E VERSO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 63875,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'LÂMINA',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 1239,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 6784,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'POST REDES SOCIAIS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 2639,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'TOTEM',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 380,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                   {
//                     nome: 'VIDEO',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 4249,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//               {
//                 nome: 'dezembro',
//                 mes: null,
//                 ano: 0,
//                 totalCusto: 0,
//                 totalVerba: 0,
//                 porcentagem: 0,
//                 custoMedio: 0,
//                 regional: null,
//                 material: [
//                   {
//                     nome: 'ENCARTE 4 PÁGINAS',
//                     mes: null,
//                     ano: 0,
//                     totalCusto: 0,
//                     totalVerba: 0,
//                     porcentagem: 0,
//                     custoMedio: 0,
//                     regional: null,
//                     material: null,
//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//         investimentoCanal: [
//           {
//             nome: 'HSA',
//             custoDiagramacao: 471918.58,
//             custoImpressao: 293353.67,
//             custoFrete: 102888.41,
//             custoMedio: 0,
//             totalCusto: 868160.66,
//             totalVerba: 0,
//             totalSolicitacoes: 1220,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 63.52,
//           },
//           {
//             nome: 'C&C Regional',
//             custoDiagramacao: 148065.55,
//             custoImpressao: 82857.47,
//             custoFrete: 40395.06,
//             custoMedio: 0,
//             totalCusto: 271318.08,
//             totalVerba: 0,
//             totalSolicitacoes: 410,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 19.85,
//           },
//           {
//             nome: 'SEM REGRA',
//             custoDiagramacao: 106462.53,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 106462.53,
//             totalVerba: 0,
//             totalSolicitacoes: 202,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 7.79,
//           },
//           {
//             nome: 'C&C Nacional',
//             custoDiagramacao: 37581.11,
//             custoImpressao: 18621.4,
//             custoFrete: 12831.6,
//             custoMedio: 0,
//             totalCusto: 69034.11,
//             totalVerba: 0,
//             totalSolicitacoes: 56,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 5.05,
//           },
//           {
//             nome: 'KAR',
//             custoDiagramacao: 21304.95,
//             custoImpressao: 25012.53,
//             custoFrete: 9837.32,
//             custoMedio: 0,
//             totalCusto: 56154.8,
//             totalVerba: 0,
//             totalSolicitacoes: 84,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 4.11,
//           },
//           {
//             nome: 'AS Nacional',
//             custoDiagramacao: 43268.12,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 43268.12,
//             totalVerba: 0,
//             totalSolicitacoes: 41,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 3.17,
//           },
//         ],
//         investimentoMaterial: [
//           {
//             nome: 'ENCARTE FRENTE E VERSO',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1837.67,
//             totalCusto: 646858.89,
//             totalVerba: 0,
//             totalSolicitacoes: 352,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 47.34,
//           },
//           {
//             nome: 'BANNER ESTÁTICO P/ SITE',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 432.76,
//             totalCusto: 149303.53,
//             totalVerba: 0,
//             totalSolicitacoes: 345,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 10.96,
//           },
//           {
//             nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 678.69,
//             totalCusto: 114020.23,
//             totalVerba: 0,
//             totalSolicitacoes: 168,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 8.4,
//           },
//           {
//             nome: 'POST REDES SOCIAIS',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 387.58,
//             totalCusto: 112011.68,
//             totalVerba: 0,
//             totalSolicitacoes: 289,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 8.22,
//           },
//           {
//             nome: 'BOX PROMOCIONAL',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 298.3,
//             totalCusto: 77260.8,
//             totalVerba: 0,
//             totalSolicitacoes: 259,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 5.65,
//           },
//           {
//             nome: 'VIDEO',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1056.74,
//             totalCusto: 61290.97,
//             totalVerba: 0,
//             totalSolicitacoes: 58,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 5.04,
//           },
//           {
//             nome: 'ADESIVO P/ FREEZER',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 944.47,
//             totalCusto: 38723.32,
//             totalVerba: 0,
//             totalSolicitacoes: 41,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 2.83,
//           },
//           {
//             nome: 'LÂMINA',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 249.78,
//             totalCusto: 36217.73,
//             totalVerba: 0,
//             totalSolicitacoes: 145,
//             porcentagemAprovada: 93,
//             porcentagemVerba: 2.68,
//           },
//           {
//             nome: 'CARTAZ',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 842.2,
//             totalCusto: 26950.36,
//             totalVerba: 0,
//             totalSolicitacoes: 32,
//             porcentagemAprovada: 93,
//             porcentagemVerba: 1.98,
//           },
//           {
//             nome: 'ADESIVO',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 428.51,
//             totalCusto: 23568.05,
//             totalVerba: 0,
//             totalSolicitacoes: 55,
//             porcentagemAprovada: 92,
//             porcentagemVerba: 1.74,
//           },
//           {
//             nome: 'BANNER FÍSICO',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 395.05,
//             totalCusto: 20937.8,
//             totalVerba: 0,
//             totalSolicitacoes: 53,
//             porcentagemAprovada: 84,
//             porcentagemVerba: 1.56,
//           },
//           {
//             nome: 'ENCARTE 4 PÁGINAS',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1391.11,
//             totalCusto: 12520.03,
//             totalVerba: 0,
//             totalSolicitacoes: 9,
//             porcentagemAprovada: 44,
//             porcentagemVerba: 1.2,
//           },
//           {
//             nome: 'EMAIL MKT',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 432.22,
//             totalCusto: 14263.21,
//             totalVerba: 0,
//             totalSolicitacoes: 33,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.04,
//           },
//           {
//             nome: 'VIDEO ATÉ 30S',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1084.75,
//             totalCusto: 14101.76,
//             totalVerba: 0,
//             totalSolicitacoes: 13,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.03,
//           },
//           {
//             nome: 'BACKLIGHT',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 507.32,
//             totalCusto: 13190.42,
//             totalVerba: 0,
//             totalSolicitacoes: 26,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.97,
//           },
//           {
//             nome: 'BOX MEIA PAGINA',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 193.55,
//             totalCusto: 11226,
//             totalVerba: 0,
//             totalSolicitacoes: 58,
//             porcentagemAprovada: 94,
//             porcentagemVerba: 0.84,
//           },
//           {
//             nome: 'BANNER ANIMADO P/ SITE',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 390.6,
//             totalCusto: 10936.91,
//             totalVerba: 0,
//             totalSolicitacoes: 28,
//             porcentagemAprovada: 92,
//             porcentagemVerba: 0.82,
//           },
//           {
//             nome: 'TABLE TENT',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 542.34,
//             totalCusto: 9762.1,
//             totalVerba: 0,
//             totalSolicitacoes: 18,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.71,
//           },
//           {
//             nome: 'TOTEM',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 825.78,
//             totalCusto: 9083.55,
//             totalVerba: 0,
//             totalSolicitacoes: 11,
//             porcentagemAprovada: 90,
//             porcentagemVerba: 0.66,
//           },
//           {
//             nome: 'SPOT DE RÁDIO (*)',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 2117.79,
//             totalCusto: 4235.58,
//             totalVerba: 0,
//             totalSolicitacoes: 2,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.31,
//           },
//           {
//             nome: 'JOGO AMERICANO',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 631.14,
//             totalCusto: 3155.69,
//             totalVerba: 0,
//             totalSolicitacoes: 5,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.23,
//           },
//           {
//             nome: 'FLYER',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 364.9,
//             totalCusto: 2554.28,
//             totalVerba: 0,
//             totalSolicitacoes: 7,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.19,
//           },
//           {
//             nome: 'CATÁLOGO',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 921.5,
//             totalCusto: 921.5,
//             totalVerba: 0,
//             totalSolicitacoes: 1,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.07,
//           },
//           {
//             nome: 'STOPPER',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 621.41,
//             totalCusto: 621.41,
//             totalVerba: 0,
//             totalSolicitacoes: 1,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.05,
//           },
//           {
//             nome: 'SPLASH',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 292.9,
//             totalCusto: 585.8,
//             totalVerba: 0,
//             totalSolicitacoes: 2,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.04,
//           },
//           {
//             nome: 'WOBBLER',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 48.35,
//             totalCusto: 96.7,
//             totalVerba: 0,
//             totalSolicitacoes: 2,
//             porcentagemAprovada: 50,
//             porcentagemVerba: 0.02,
//           },
//         ],
//         investimentoProduto: [
//           {
//             nome: 'NUGGETS SADIA SABORES 300G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 940.56,
//             totalCusto: 280286.84,
//             totalVerba: 0,
//             totalSolicitacoes: 298,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 20.51,
//           },
//           {
//             nome: 'PIZZA SADIA SABORES 440/460/480G ',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 849.62,
//             totalCusto: 247238.6,
//             totalVerba: 0,
//             totalSolicitacoes: 291,
//             porcentagemAprovada: 95,
//             porcentagemVerba: 18.09,
//           },
//           {
//             nome: "MAC'N CHEESE SADIA SABORES 350G",
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 969.72,
//             totalCusto: 233701.9,
//             totalVerba: 0,
//             totalSolicitacoes: 241,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 17.1,
//           },
//           {
//             nome: 'Pizza Sadia Sabores 440/460g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1435.74,
//             totalCusto: 213924.76,
//             totalVerba: 0,
//             totalSolicitacoes: 149,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 15.65,
//           },
//           {
//             nome: 'HAMBURGUER PERDIGÃO SABORES 672g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1276.48,
//             totalCusto: 208066.53,
//             totalVerba: 0,
//             totalSolicitacoes: 163,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 15.22,
//           },
//           {
//             nome: 'PEITO DE PERU DEFUMADO SADIA',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1179.36,
//             totalCusto: 202850.27,
//             totalVerba: 0,
//             totalSolicitacoes: 172,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 14.84,
//           },
//           {
//             nome: 'MACARRÃO HOT BOWLS SADIA SABORES 300g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1447.25,
//             totalCusto: 198273.37,
//             totalVerba: 0,
//             totalSolicitacoes: 137,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 14.51,
//           },
//           {
//             nome: 'MINI CHICKEN  PERDIGÃO SABORES 275G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1132.44,
//             totalCusto: 198177.58,
//             totalVerba: 0,
//             totalSolicitacoes: 175,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 14.5,
//           },
//           {
//             nome: 'LASANHA PERDIGÃO SABORES 600G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 857.52,
//             totalCusto: 194656.81,
//             totalVerba: 0,
//             totalSolicitacoes: 227,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 14.24,
//           },
//           {
//             nome: 'Nuggets Sadia Sabores 300g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1121.13,
//             totalCusto: 192834.3,
//             totalVerba: 0,
//             totalSolicitacoes: 172,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 14.11,
//           },
//           {
//             nome: 'nulo',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 583.08,
//             totalCusto: 189499.5,
//             totalVerba: 0,
//             totalSolicitacoes: 325,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 13.87,
//           },
//           {
//             nome: 'MARGARINA QUALY SABORES 500G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1152.3,
//             totalCusto: 179758.2,
//             totalVerba: 0,
//             totalSolicitacoes: 156,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 13.15,
//           },
//           {
//             nome: 'STEAK DE FRANGO PERDIGÃO 100G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1219,
//             totalCusto: 179193.71,
//             totalVerba: 0,
//             totalSolicitacoes: 147,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 13.11,
//           },
//           {
//             nome: 'PRESUNTO COZIDO PERDIGÃO',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1197.13,
//             totalCusto: 174780.88,
//             totalVerba: 0,
//             totalSolicitacoes: 146,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 12.79,
//           },
//           {
//             nome: 'Lasanha Perdigão Sabores 600g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1319.93,
//             totalCusto: 174230.78,
//             totalVerba: 0,
//             totalSolicitacoes: 132,
//             porcentagemAprovada: 99,
//             porcentagemVerba: 12.75,
//           },
//           {
//             nome: 'LASANHA SADIA SABORES 600G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 909.06,
//             totalCusto: 169994.83,
//             totalVerba: 0,
//             totalSolicitacoes: 187,
//             porcentagemAprovada: 95,
//             porcentagemVerba: 12.44,
//           },
//           {
//             nome: 'MORTADELA OURO PERDIGÃO ',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 886.77,
//             totalCusto: 165825.87,
//             totalVerba: 0,
//             totalSolicitacoes: 187,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 12.13,
//           },
//           {
//             nome: 'SALSICHA SADIA SABORES 500G ',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1145.15,
//             totalCusto: 160320.82,
//             totalVerba: 0,
//             totalSolicitacoes: 140,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 11.73,
//           },
//           {
//             nome: 'SALSICHA HOT DOG PERDIGÃO ',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1411.14,
//             totalCusto: 158047.31,
//             totalVerba: 0,
//             totalSolicitacoes: 112,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 11.56,
//           },
//           {
//             nome: 'MORTADELA DEFUMADA SADIA ',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1107.38,
//             totalCusto: 156140.39,
//             totalVerba: 0,
//             totalSolicitacoes: 141,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 11.42,
//           },
//           {
//             nome: 'LINGUIÇA TOSCANA PERDIGÃO NA BRASA SABORES 600G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1054.13,
//             totalCusto: 154957.34,
//             totalVerba: 0,
//             totalSolicitacoes: 147,
//             porcentagemAprovada: 95,
//             porcentagemVerba: 11.34,
//           },
//           {
//             nome: 'Steak Perdigão 100g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1460.09,
//             totalCusto: 154769.44,
//             totalVerba: 0,
//             totalSolicitacoes: 106,
//             porcentagemAprovada: 99,
//             porcentagemVerba: 11.32,
//           },
//           {
//             nome: 'Hambúrguer Perdigão Sabores 672g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1537.42,
//             totalCusto: 153742.49,
//             totalVerba: 0,
//             totalSolicitacoes: 100,
//             porcentagemAprovada: 99,
//             porcentagemVerba: 11.25,
//           },
//           {
//             nome: 'Lasanha Sadia Sabores 600g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1210.27,
//             totalCusto: 153704.14,
//             totalVerba: 0,
//             totalSolicitacoes: 127,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 11.25,
//           },
//           {
//             nome: 'MARGARINA  BECEL SABORES 500G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1180.14,
//             totalCusto: 153418.19,
//             totalVerba: 0,
//             totalSolicitacoes: 130,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 11.23,
//           },
//           {
//             nome: 'Peito de Peru Sadia',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1591.15,
//             totalCusto: 152750.61,
//             totalVerba: 0,
//             totalSolicitacoes: 96,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 11.18,
//           },
//           {
//             nome: 'Hot Pocket Sadia Sabores 145g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1445.32,
//             totalCusto: 151758.12,
//             totalVerba: 0,
//             totalSolicitacoes: 105,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 11.1,
//           },
//           {
//             nome: 'HAMBÚRGUER SADIA SABORES 672G',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1255.42,
//             totalCusto: 146884.42,
//             totalVerba: 0,
//             totalSolicitacoes: 117,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 10.75,
//           },
//           {
//             nome: 'LINGUIÇA TOSCANA PERDIGÃO NA BRASA SABORES 1KG',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 1092.03,
//             totalCusto: 144147.31,
//             totalVerba: 0,
//             totalSolicitacoes: 132,
//             porcentagemAprovada: 93,
//             porcentagemVerba: 10.55,
//           },
//           {
//             nome: 'Steak Sadia 100g',
//             custoDiagramacao: 0,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 2331.16,
//             totalCusto: 142200.77,
//             totalVerba: 0,
//             totalSolicitacoes: 61,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 10.4,
//           },
//         ],
//         investimentoSolicitante: [
//           {
//             nome: 'ROBSON TADEU MARQUES VAZ',
//             custoDiagramacao: 7564.52,
//             custoImpressao: 51852.12,
//             custoFrete: 18292.5,
//             custoMedio: 0,
//             totalCusto: 77709.14,
//             totalVerba: 0,
//             totalSolicitacoes: 31,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 5.69,
//           },
//           {
//             nome: 'FERNANDO CURBAN CROCCO',
//             custoDiagramacao: 60327.34,
//             custoImpressao: 4882.6,
//             custoFrete: 4034.2,
//             custoMedio: 0,
//             totalCusto: 69244.14,
//             totalVerba: 0,
//             totalSolicitacoes: 61,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 5.07,
//           },
//           {
//             nome: 'HEBERSON BARBOZA DE SOUZA',
//             custoDiagramacao: 3238.19,
//             custoImpressao: 45842.33,
//             custoFrete: 15525.6,
//             custoMedio: 0,
//             totalCusto: 64606.12,
//             totalVerba: 0,
//             totalSolicitacoes: 31,
//             porcentagemAprovada: 90,
//             porcentagemVerba: 4.74,
//           },
//           {
//             nome: 'CLARICE RANGEL DE ALMEIDA BERTOLINI',
//             custoDiagramacao: 61547.44,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 61547.44,
//             totalVerba: 0,
//             totalSolicitacoes: 124,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 4.51,
//           },
//           {
//             nome: 'DEBORAH DA SILVEIRA OLIVEIRA',
//             custoDiagramacao: 35363.22,
//             custoImpressao: 8856.2,
//             custoFrete: 4763.2,
//             custoMedio: 0,
//             totalCusto: 48982.62,
//             totalVerba: 0,
//             totalSolicitacoes: 32,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 3.58,
//           },
//           {
//             nome: 'LEIDE DAYANA DE SOUSA FACUNDO',
//             custoDiagramacao: 928.04,
//             custoImpressao: 32909.07,
//             custoFrete: 10001.6,
//             custoMedio: 0,
//             totalCusto: 43838.71,
//             totalVerba: 0,
//             totalSolicitacoes: 9,
//             porcentagemAprovada: 88,
//             porcentagemVerba: 3.21,
//           },
//           {
//             nome: 'BRUNA ELIZABETH',
//             custoDiagramacao: 30907.85,
//             custoImpressao: 6109.65,
//             custoFrete: 6208.15,
//             custoMedio: 0,
//             totalCusto: 43225.65,
//             totalVerba: 0,
//             totalSolicitacoes: 79,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 3.17,
//           },
//           {
//             nome: 'MARIANA VITORIA DO PRADO BARBOSA',
//             custoDiagramacao: 35937.49,
//             custoImpressao: 561.1,
//             custoFrete: 179.4,
//             custoMedio: 0,
//             totalCusto: 36677.99,
//             totalVerba: 0,
//             totalSolicitacoes: 76,
//             porcentagemAprovada: 96,
//             porcentagemVerba: 2.71,
//           },
//           {
//             nome: 'TIAGO MENDES',
//             custoDiagramacao: 28161.26,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 28161.26,
//             totalVerba: 0,
//             totalSolicitacoes: 41,
//             porcentagemAprovada: 87,
//             porcentagemVerba: 2.65,
//           },
//           {
//             nome: 'JONATHAN FERREIRA DOS SANTOS',
//             custoDiagramacao: 2664.38,
//             custoImpressao: 20168.72,
//             custoFrete: 10857.8,
//             custoMedio: 0,
//             totalCusto: 33690.9,
//             totalVerba: 0,
//             totalSolicitacoes: 10,
//             porcentagemAprovada: 90,
//             porcentagemVerba: 2.47,
//           },
//           {
//             nome: 'WESLEI BARBOSA DE LIMA',
//             custoDiagramacao: 850.82,
//             custoImpressao: 22926.42,
//             custoFrete: 7533.6,
//             custoMedio: 0,
//             totalCusto: 31310.84,
//             totalVerba: 0,
//             totalSolicitacoes: 8,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 2.29,
//           },
//           {
//             nome: 'RODRIGO FERREIRA BARBOSA',
//             custoDiagramacao: 628.4,
//             custoImpressao: 13863.49,
//             custoFrete: 12948.61,
//             custoMedio: 0,
//             totalCusto: 27440.5,
//             totalVerba: 0,
//             totalSolicitacoes: 7,
//             porcentagemAprovada: 85,
//             porcentagemVerba: 2.27,
//           },
//           {
//             nome: 'GERALDO RODRIGO ALVES DE GODOI',
//             custoDiagramacao: 734.64,
//             custoImpressao: 22737.81,
//             custoFrete: 6245,
//             custoMedio: 0,
//             totalCusto: 29717.45,
//             totalVerba: 0,
//             totalSolicitacoes: 6,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 2.17,
//           },
//           {
//             nome: 'FABIO AUGUSTO CIRULLI',
//             custoDiagramacao: 2646.46,
//             custoImpressao: 20454,
//             custoFrete: 5743.76,
//             custoMedio: 0,
//             totalCusto: 28844.22,
//             totalVerba: 0,
//             totalSolicitacoes: 14,
//             porcentagemAprovada: 92,
//             porcentagemVerba: 2.12,
//           },
//           {
//             nome: 'CAMILA LEIBEL',
//             custoDiagramacao: 15920.85,
//             custoImpressao: 5879.9,
//             custoFrete: 4400.6,
//             custoMedio: 0,
//             totalCusto: 26201.35,
//             totalVerba: 0,
//             totalSolicitacoes: 20,
//             porcentagemAprovada: 95,
//             porcentagemVerba: 1.92,
//           },
//           {
//             nome: 'ELCIO BUENO DOS SANTOS',
//             custoDiagramacao: 1013.92,
//             custoImpressao: 19499.4,
//             custoFrete: 5610.6,
//             custoMedio: 0,
//             totalCusto: 26123.92,
//             totalVerba: 0,
//             totalSolicitacoes: 5,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.91,
//           },
//           {
//             nome: 'WAGNER BAIO',
//             custoDiagramacao: 13415.73,
//             custoImpressao: 7218.8,
//             custoFrete: 1870.2,
//             custoMedio: 0,
//             totalCusto: 22504.73,
//             totalVerba: 0,
//             totalSolicitacoes: 28,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.65,
//           },
//           {
//             nome: 'ALVARO SEABRA',
//             custoDiagramacao: 21986.68,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 21986.68,
//             totalVerba: 0,
//             totalSolicitacoes: 70,
//             porcentagemAprovada: 98,
//             porcentagemVerba: 1.62,
//           },
//           {
//             nome: 'JONATHAN CARVALHO DA SILVA',
//             custoDiagramacao: 7863.92,
//             custoImpressao: 10313.2,
//             custoFrete: 3387.4,
//             custoMedio: 0,
//             totalCusto: 21564.52,
//             totalVerba: 0,
//             totalSolicitacoes: 27,
//             porcentagemAprovada: 88,
//             porcentagemVerba: 1.59,
//           },
//           {
//             nome: 'FREDERICO LOUBACK HERINGER',
//             custoDiagramacao: 560.72,
//             custoImpressao: 12294.29,
//             custoFrete: 8643.6,
//             custoMedio: 0,
//             totalCusto: 21498.61,
//             totalVerba: 0,
//             totalSolicitacoes: 5,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.57,
//           },
//           {
//             nome: 'THIAGO ROCHA',
//             custoDiagramacao: 21380.92,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 21380.92,
//             totalVerba: 0,
//             totalSolicitacoes: 70,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.56,
//           },
//           {
//             nome: 'JÉSSICA MARIA DA CUNHA GUERERO',
//             custoDiagramacao: 1101.96,
//             custoImpressao: 15643.15,
//             custoFrete: 4258.8,
//             custoMedio: 0,
//             totalCusto: 21003.91,
//             totalVerba: 0,
//             totalSolicitacoes: 9,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.54,
//           },
//           {
//             nome: 'CAMILA PAVAN TOZELLI',
//             custoDiagramacao: 19444.93,
//             custoImpressao: 870.1,
//             custoFrete: 297.1,
//             custoMedio: 0,
//             totalCusto: 20612.13,
//             totalVerba: 0,
//             totalSolicitacoes: 35,
//             porcentagemAprovada: 97,
//             porcentagemVerba: 1.53,
//           },
//           {
//             nome: 'GLAUCE DA SILVA BARBARA',
//             custoDiagramacao: 20352.1,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 20352.1,
//             totalVerba: 0,
//             totalSolicitacoes: 31,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.49,
//           },
//           {
//             nome: 'MILENA XIMENES',
//             custoDiagramacao: 19755.48,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 19755.48,
//             totalVerba: 0,
//             totalSolicitacoes: 35,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.45,
//           },
//           {
//             nome: 'YGOR BERRIEL DUARTE',
//             custoDiagramacao: 18949.6,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 18949.6,
//             totalVerba: 0,
//             totalSolicitacoes: 47,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.39,
//           },
//           {
//             nome: 'JOÃO PEDRO MOTTA LOURENÇO',
//             custoDiagramacao: 17741.77,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 17741.77,
//             totalVerba: 0,
//             totalSolicitacoes: 34,
//             porcentagemAprovada: 91,
//             porcentagemVerba: 1.3,
//           },
//           {
//             nome: 'MARCOS VINCO ',
//             custoDiagramacao: 16749.1,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 16749.1,
//             totalVerba: 0,
//             totalSolicitacoes: 41,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.23,
//           },
//           {
//             nome: 'ALEX MESSIAS',
//             custoDiagramacao: 16364.55,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 16364.55,
//             totalVerba: 0,
//             totalSolicitacoes: 40,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.2,
//           },
//           {
//             nome: 'RENATA VICTORIA DE SOUZA BERNARDO',
//             custoDiagramacao: 13938.16,
//             custoImpressao: 1024.2,
//             custoFrete: 400.66,
//             custoMedio: 0,
//             totalCusto: 15363.02,
//             totalVerba: 0,
//             totalSolicitacoes: 29,
//             porcentagemAprovada: 93,
//             porcentagemVerba: 1.12,
//           },
//           {
//             nome: 'CAMILA ESTEFANATI',
//             custoDiagramacao: 4935.91,
//             custoImpressao: 7442.58,
//             custoFrete: 2870,
//             custoMedio: 0,
//             totalCusto: 15248.49,
//             totalVerba: 0,
//             totalSolicitacoes: 20,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.12,
//           },
//           {
//             nome: 'EVANDRO ROBBI DE GODOI',
//             custoDiagramacao: 580.2,
//             custoImpressao: 11177.78,
//             custoFrete: 3451.12,
//             custoMedio: 0,
//             totalCusto: 15209.1,
//             totalVerba: 0,
//             totalSolicitacoes: 5,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.11,
//           },
//           {
//             nome: 'HALLEY DE MORAIS SILVA',
//             custoDiagramacao: 14059.45,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 14059.45,
//             totalVerba: 0,
//             totalSolicitacoes: 45,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.03,
//           },
//           {
//             nome: 'JULIO CESAR FARIA GONCALVES',
//             custoDiagramacao: 857.65,
//             custoImpressao: 10053.15,
//             custoFrete: 3078.14,
//             custoMedio: 0,
//             totalCusto: 13988.94,
//             totalVerba: 0,
//             totalSolicitacoes: 5,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1.02,
//           },
//           {
//             nome: 'FAUSTO DA SILVA GOMES',
//             custoDiagramacao: 5293.54,
//             custoImpressao: 6499.8,
//             custoFrete: 1870.2,
//             custoMedio: 0,
//             totalCusto: 13663.54,
//             totalVerba: 0,
//             totalSolicitacoes: 16,
//             porcentagemAprovada: 93,
//             porcentagemVerba: 1,
//           },
//           {
//             nome: 'MAYLSON GONZAGA RODRIGUES',
//             custoDiagramacao: 135.31,
//             custoImpressao: 10289.3,
//             custoFrete: 3220.9,
//             custoMedio: 0,
//             totalCusto: 13645.51,
//             totalVerba: 0,
//             totalSolicitacoes: 1,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 1,
//           },
//           {
//             nome: 'NAIDE CALCI PEREIRA',
//             custoDiagramacao: 1394.3,
//             custoImpressao: 7176.72,
//             custoFrete: 4788,
//             custoMedio: 0,
//             totalCusto: 13359.02,
//             totalVerba: 0,
//             totalSolicitacoes: 7,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.98,
//           },
//           {
//             nome: 'CLOVIANE CAMARGO DE ABREU SANTUCCI',
//             custoDiagramacao: 12399.36,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 12399.36,
//             totalVerba: 0,
//             totalSolicitacoes: 42,
//             porcentagemAprovada: 92,
//             porcentagemVerba: 0.93,
//           },
//           {
//             nome: 'EMERSON ROLDAO DE JESUS',
//             custoDiagramacao: 12268.69,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 12268.69,
//             totalVerba: 0,
//             totalSolicitacoes: 49,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.9,
//           },
//           {
//             nome: 'MÔNICA RICARDO DE VASCONCELOS ANDRADE',
//             custoDiagramacao: 11695.37,
//             custoImpressao: 0,
//             custoFrete: 0,
//             custoMedio: 0,
//             totalCusto: 11695.37,
//             totalVerba: 0,
//             totalSolicitacoes: 17,
//             porcentagemAprovada: 100,
//             porcentagemVerba: 0.86,
//           },
//         ],
//         labelsMaterial: [
//           'ADESIVO',
//           'ADESIVO P/ FREEZER',
//           'BACKLIGHT',
//           'BANNER ANIMADO P/ SITE',
//           'BANNER ESTÁTICO P/ SITE',
//           'BANNER FÍSICO',
//           'BOX MEIA PAGINA',
//           'BOX PROMOCIONAL',
//           'CARTAZ',
//           'CATÁLOGO',
//           'EMAIL MKT',
//           'ENCARTE 4 PÁGINAS',
//           'ENCARTE FRENTE E VERSO',
//           'FLYER',
//           'JOGO AMERICANO',
//           'LÂMINA',
//           'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//           'POST REDES SOCIAIS',
//           'SPLASH',
//           'SPOT DE RÁDIO (*)',
//           'STOPPER',
//           'TABLE TENT',
//           'TOTEM',
//           'VIDEO',
//           'VIDEO ATÉ 30S',
//           'WOBBLER',
//         ],
//       },
//       participacao: {
//         participacaoUF: [
//           {
//             nome: 'SP',
//             total: 135,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 37.4,
//           },
//           {
//             nome: 'MS',
//             total: 28,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 7.76,
//           },
//           {
//             nome: 'MG',
//             total: 19,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 5.26,
//           },
//           {
//             nome: 'PR',
//             total: 18,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 4.99,
//           },
//           {
//             nome: 'RJ',
//             total: 17,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 4.71,
//           },
//           {
//             nome: 'CE',
//             total: 17,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 4.71,
//           },
//           {
//             nome: 'DF',
//             total: 16,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 4.43,
//           },
//           {
//             nome: 'BA',
//             total: 16,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 4.43,
//           },
//           {
//             nome: 'PA',
//             total: 14,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 3.88,
//           },
//           {
//             nome: 'MT',
//             total: 12,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 3.32,
//           },
//           {
//             nome: 'AM',
//             total: 12,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 3.32,
//           },
//           {
//             nome: 'PE',
//             total: 12,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 3.32,
//           },
//           {
//             nome: 'SC',
//             total: 10,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 2.77,
//           },
//           {
//             nome: 'MA',
//             total: 8,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 2.22,
//           },
//           {
//             nome: 'AP',
//             total: 6,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 1.66,
//           },
//           {
//             nome: 'ES',
//             total: 5,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 1.39,
//           },
//           {
//             nome: 'RS',
//             total: 4,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 1.11,
//           },
//           {
//             nome: 'SE',
//             total: 4,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 1.11,
//           },
//           {
//             nome: 'RO',
//             total: 3,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0.83,
//           },
//           {
//             nome: 'AL',
//             total: 2,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0.55,
//           },
//           {
//             nome: 'PI',
//             total: 1,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0.28,
//           },
//           {
//             nome: 'RR',
//             total: 1,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0.28,
//           },
//           {
//             nome: 'GO',
//             total: 1,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0.28,
//           },
//           {
//             nome: 'RN',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//         ],
//         participacaoRegional: [
//           {
//             nome: 'SAO PAULO',
//             total: 0,
//             totalObrigatorio: 92,
//             totalNaoObrigatorio: 134,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'NO/NE',
//             total: 0,
//             totalObrigatorio: 65,
//             totalNaoObrigatorio: 95,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SE/CO',
//             total: 0,
//             totalObrigatorio: 55,
//             totalNaoObrigatorio: 95,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUL',
//             total: 0,
//             totalObrigatorio: 17,
//             totalNaoObrigatorio: 31,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'TRADE COORPORATIVO',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//         ],
//         participacaoRede: [
//           {
//             nome: 'REDE COMPER',
//             total: 0,
//             totalObrigatorio: 45,
//             totalNaoObrigatorio: 89,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CENCOSUD',
//             total: 0,
//             totalObrigatorio: 30,
//             totalNaoObrigatorio: 89,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'COVABRA',
//             total: 0,
//             totalObrigatorio: 27,
//             totalNaoObrigatorio: 50,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUPERNOSSO APOIO',
//             total: 0,
//             totalObrigatorio: 26,
//             totalNaoObrigatorio: 64,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUPERMARKET',
//             total: 0,
//             totalObrigatorio: 19,
//             totalNaoObrigatorio: 56,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'INSTITUCIONAL SE',
//             total: 0,
//             totalObrigatorio: 19,
//             totalNaoObrigatorio: 101,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'BAHAMAS',
//             total: 0,
//             totalObrigatorio: 16,
//             totalNaoObrigatorio: 48,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FESTIVAL',
//             total: 0,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 46,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MATEUS',
//             total: 0,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 33,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ANDORINHA',
//             total: 0,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 33,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LIDER',
//             total: 0,
//             totalObrigatorio: 14,
//             totalNaoObrigatorio: 34,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FORMOSA',
//             total: 0,
//             totalObrigatorio: 13,
//             totalNaoObrigatorio: 26,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SEMAR',
//             total: 0,
//             totalObrigatorio: 12,
//             totalNaoObrigatorio: 34,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'VERDEMAR',
//             total: 0,
//             totalObrigatorio: 12,
//             totalNaoObrigatorio: 36,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MUNDIAL',
//             total: 0,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 26,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUP RONDON',
//             total: 0,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 23,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'DALBEN',
//             total: 0,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 35,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'BIG BOX',
//             total: 0,
//             totalObrigatorio: 10,
//             totalNaoObrigatorio: 18,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'ASSAI',
//             total: 0,
//             totalObrigatorio: 9,
//             totalNaoObrigatorio: 37,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'BISTEK',
//             total: 0,
//             totalObrigatorio: 9,
//             totalNaoObrigatorio: 30,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'REDE BH',
//             total: 0,
//             totalObrigatorio: 9,
//             totalNaoObrigatorio: 25,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CEMA',
//             total: 0,
//             totalObrigatorio: 9,
//             totalNaoObrigatorio: 21,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'JAD ZOGHEIB',
//             total: 0,
//             totalObrigatorio: 8,
//             totalNaoObrigatorio: 19,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'DB',
//             total: 0,
//             totalObrigatorio: 8,
//             totalNaoObrigatorio: 21,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FAMILIA',
//             total: 0,
//             totalObrigatorio: 8,
//             totalNaoObrigatorio: 17,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MUFFATO',
//             total: 0,
//             totalObrigatorio: 8,
//             totalNaoObrigatorio: 11,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CENTERBOX',
//             total: 0,
//             totalObrigatorio: 7,
//             totalNaoObrigatorio: 15,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'CAVICCHIOLLI',
//             total: 0,
//             totalObrigatorio: 7,
//             totalNaoObrigatorio: 16,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'GIASSI',
//             total: 0,
//             totalObrigatorio: 6,
//             totalNaoObrigatorio: 15,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SUPER A FRANGOLANDIA',
//             total: 0,
//             totalObrigatorio: 6,
//             totalNaoObrigatorio: 22,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//         ],
//         encarteProdutoObrigatorio: [
//           {
//             nome: 'outubro - 2022',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 16,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'novembro - 2022',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 26,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'dezembro - 2022',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 16,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'janeiro - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 11,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'fevereiro - 2023',
//             total: 0,
//             totalObrigatorio: 2,
//             totalNaoObrigatorio: 11,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'março - 2023',
//             total: 0,
//             totalObrigatorio: 20,
//             totalNaoObrigatorio: 34,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'abril - 2023',
//             total: 0,
//             totalObrigatorio: 36,
//             totalNaoObrigatorio: 37,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'maio - 2023',
//             total: 0,
//             totalObrigatorio: 43,
//             totalNaoObrigatorio: 44,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'junho - 2023',
//             total: 0,
//             totalObrigatorio: 41,
//             totalNaoObrigatorio: 44,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'julho - 2023',
//             total: 0,
//             totalObrigatorio: 16,
//             totalNaoObrigatorio: 29,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'agosto - 2023',
//             total: 0,
//             totalObrigatorio: 25,
//             totalNaoObrigatorio: 41,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'setembro - 2023',
//             total: 0,
//             totalObrigatorio: 22,
//             totalNaoObrigatorio: 28,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'outubro - 2023',
//             total: 0,
//             totalObrigatorio: 23,
//             totalNaoObrigatorio: 17,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'dezembro - 2023',
//             total: 0,
//             totalObrigatorio: 1,
//             totalNaoObrigatorio: 1,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//         ],
//         encarteCategoria: [
//           {
//             nome: 'LINGUIÇA DEFUMADA',
//             total: 0,
//             totalObrigatorio: 81,
//             totalNaoObrigatorio: 125,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SALSICHA (DOMESTICA)',
//             total: 0,
//             totalObrigatorio: 64,
//             totalNaoObrigatorio: 9,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FATIADOS SOLTÍSSIMO',
//             total: 0,
//             totalObrigatorio: 51,
//             totalNaoObrigatorio: 81,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'EMPANADOS',
//             total: 0,
//             totalObrigatorio: 51,
//             totalNaoObrigatorio: 219,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'EMPANADO (DOMESTICO)',
//             total: 0,
//             totalObrigatorio: 51,
//             totalNaoObrigatorio: 83,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LASANHAS',
//             total: 0,
//             totalObrigatorio: 48,
//             totalNaoObrigatorio: 287,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MORTADELA MÉDIA',
//             total: 0,
//             totalObrigatorio: 44,
//             totalNaoObrigatorio: 221,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PRATOS PRONTOS',
//             total: 0,
//             totalObrigatorio: 42,
//             totalNaoObrigatorio: 233,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARGARINA',
//             total: 0,
//             totalObrigatorio: 39,
//             totalNaoObrigatorio: 205,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUIÇA FRESCAL',
//             total: 0,
//             totalObrigatorio: 29,
//             totalNaoObrigatorio: 159,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PRESUNTARIA',
//             total: 0,
//             totalObrigatorio: 28,
//             totalNaoObrigatorio: 186,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PIZZAS',
//             total: 0,
//             totalObrigatorio: 28,
//             totalNaoObrigatorio: 224,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SALAME',
//             total: 0,
//             totalObrigatorio: 27,
//             totalNaoObrigatorio: 150,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARGARINA QUALY',
//             total: 0,
//             totalObrigatorio: 26,
//             totalNaoObrigatorio: 55,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MORTADELA TUBULAR',
//             total: 0,
//             totalObrigatorio: 23,
//             totalNaoObrigatorio: 70,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUICA DEFUMADA (DOMESTICA)',
//             total: 0,
//             totalObrigatorio: 18,
//             totalNaoObrigatorio: 62,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'EMPANADO (GRANEL)',
//             total: 0,
//             totalObrigatorio: 16,
//             totalNaoObrigatorio: 61,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'IN NATURA-NA MEDIDA',
//             total: 0,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 66,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'IN NATURA-SUÍNOS CONGELADOS',
//             total: 0,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 22,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FATIADO PRESUNTO',
//             total: 0,
//             totalObrigatorio: 13,
//             totalNaoObrigatorio: 32,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PEITO PERU',
//             total: 0,
//             totalObrigatorio: 12,
//             totalNaoObrigatorio: 50,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARGARINA CLAYBOM',
//             total: 0,
//             totalObrigatorio: 12,
//             totalNaoObrigatorio: 43,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARGARINA BECEL',
//             total: 0,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 52,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PRATO PRONTO',
//             total: 0,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 53,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LANCHE PRONTO',
//             total: 0,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 46,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PRESUNTO SUINO',
//             total: 0,
//             totalObrigatorio: 10,
//             totalNaoObrigatorio: 36,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'HAMBURGUER (DOMESTICO)',
//             total: 0,
//             totalObrigatorio: 10,
//             totalNaoObrigatorio: 58,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FATIADO MORTADELA MEDIA',
//             total: 0,
//             totalObrigatorio: 10,
//             totalNaoObrigatorio: 37,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'FATIADO SALAME',
//             total: 0,
//             totalObrigatorio: 9,
//             totalNaoObrigatorio: 36,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'IN NATURA-CORTES DE FRANGO',
//             total: 0,
//             totalObrigatorio: 8,
//             totalNaoObrigatorio: 27,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//         ],
//         encarteProduto: [
//           {
//             nome: 'NUGGETS SADIA SABORES 300G',
//             total: 162,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 162,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LASANHA PERDIGÃO SABORES 600G',
//             total: 135,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 135,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: "MAC'N CHEESE SADIA SABORES 350G",
//             total: 131,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 131,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PIZZA SADIA SABORES 440/460/480G ',
//             total: 130,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 130,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MINI CHICKEN  PERDIGÃO SABORES 275G',
//             total: 119,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 119,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PEITO DE PERU DEFUMADO SADIA',
//             total: 114,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 114,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'STEAK DE FRANGO PERDIGÃO 100G',
//             total: 113,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 113,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'HAMBURGUER PERDIGÃO SABORES 672g',
//             total: 108,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 108,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MORTADELA OURO PERDIGÃO ',
//             total: 107,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 107,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SALSICHA SADIA SABORES 500G ',
//             total: 90,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 90,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LASANHA SADIA SABORES 600G',
//             total: 89,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 89,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARGARINA QUALY SABORES 500G',
//             total: 80,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 80,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PRESUNTO COZIDO S/CAPA GORD SADIA',
//             total: 79,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 79,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Nuggets Sadia Sabores 300g',
//             total: 78,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 63,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARGARINA  BECEL SABORES 500G',
//             total: 77,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 77,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PRESUNTO COZIDO PERDIGÃO',
//             total: 76,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 76,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MORTADELA DEFUMADA SADIA ',
//             total: 75,
//             totalObrigatorio: 31,
//             totalNaoObrigatorio: 44,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SALSICHA PERDIGÃO SABORES 500G',
//             total: 75,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 75,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Lasanha Perdigão Sabores 600g',
//             total: 75,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 60,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'HOT POCKET SADIA SABORES 145G',
//             total: 72,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 72,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PRESUNTO COZIDO SOLTISSIMO SADIA 200G',
//             total: 71,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 71,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MORTADELA OURO PERDIGÃO SABORES 200G ',
//             total: 71,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 71,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUIÇA TOSCANA PERDIGÃO NA BRASA SABORES 1KG',
//             total: 70,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 70,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'BIG CHICKEN PERDIGÃO SABORES 1KG',
//             total: 69,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 69,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Steak Perdigão 100g',
//             total: 68,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 57,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUIÇA TOSCANA PERDIGÃO NA BRASA SABORES 600G',
//             total: 67,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 67,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'STEAK DE FRANGO SADIA 100G',
//             total: 66,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 66,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Pizza Sadia Sabores 440/460g',
//             total: 66,
//             totalObrigatorio: 12,
//             totalNaoObrigatorio: 54,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'HAMBÚRGUER SADIA SABORES 672G',
//             total: 65,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 65,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MEU MENU PERDIGÃO SABORES 350G',
//             total: 64,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 64,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SALSICHA HOT DOG PERDIGÃO ',
//             total: 64,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 64,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUIÇA TIPO CALABRESA SADIA 500G',
//             total: 63,
//             totalObrigatorio: 44,
//             totalNaoObrigatorio: 19,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'PIZZA  PERDIGÃO SABORES 460G',
//             total: 63,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 63,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUIÇA FININHA PERDIGÃO 240G',
//             total: 62,
//             totalObrigatorio: 39,
//             totalNaoObrigatorio: 23,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'HAMBURGUER PERDIGÃO SABORES 56G',
//             total: 60,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 60,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LASB- LASANHA BOLONHESA SADIA 600G - ',
//             total: 59,
//             totalObrigatorio: 16,
//             totalNaoObrigatorio: 43,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MACARRÃO HOT BOWLS SADIA SABORES ',
//             total: 59,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 59,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Peito de Peru Sadia',
//             total: 59,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 48,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Margarina Becel Sabores 500g',
//             total: 59,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 48,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MACARRÃO HOT BOWLS SADIA SABORES 300g',
//             total: 59,
//             totalObrigatorio: 9,
//             totalNaoObrigatorio: 50,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUIÇA TIPO CALABRESA PERDIGÃO 400G',
//             total: 57,
//             totalObrigatorio: 38,
//             totalNaoObrigatorio: 19,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Hot Pocket Sadia Sabores 145g',
//             total: 57,
//             totalObrigatorio: 11,
//             totalNaoObrigatorio: 46,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARGARINA QUALY SABORES 250G',
//             total: 56,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 56,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'SALAME TIPO ITALIANO SADIA',
//             total: 56,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 56,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUIÇA FININHA SADIA 240G',
//             total: 55,
//             totalObrigatorio: 31,
//             totalNaoObrigatorio: 24,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: " MAC'N CHEESE CALABRESA 350G",
//             total: 54,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 39,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'MARGARINA CLAYBOM SABORES 500G',
//             total: 54,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 54,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Mini Chicken Perdigão Sabores 275g',
//             total: 54,
//             totalObrigatorio: 13,
//             totalNaoObrigatorio: 41,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'Hambúrguer Perdigão Sabores 672g',
//             total: 53,
//             totalObrigatorio: 4,
//             totalNaoObrigatorio: 49,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: 'LINGUICA TIPO CALABRESA SADIA 2,5KG',
//             total: 50,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 50,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//         ],
//         mediaEncarte: [
//           {
//             nome: 'outubro - 2022',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 15.56,
//             porcentagem: 0,
//           },
//           {
//             nome: 'novembro - 2022',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 17.52,
//             porcentagem: 0,
//           },
//           {
//             nome: 'dezembro - 2022',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 16.7,
//             porcentagem: 0,
//           },
//           {
//             nome: 'janeiro - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 16.16,
//             porcentagem: 0,
//           },
//           {
//             nome: 'fevereiro - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 20.99,
//             precoMedioNaoObrigatorio: 18.6,
//             porcentagem: 0,
//           },
//           {
//             nome: 'março - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 12.63,
//             precoMedioNaoObrigatorio: 16.75,
//             porcentagem: 0,
//           },
//           {
//             nome: 'abril - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 16.25,
//             precoMedioNaoObrigatorio: 15.67,
//             porcentagem: 0,
//           },
//           {
//             nome: 'maio - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 16.9,
//             precoMedioNaoObrigatorio: 16.79,
//             porcentagem: 0,
//           },
//           {
//             nome: 'junho - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 18.46,
//             precoMedioNaoObrigatorio: 17.21,
//             porcentagem: 0,
//           },
//           {
//             nome: 'julho - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 20.2,
//             precoMedioNaoObrigatorio: 26.23,
//             porcentagem: 0,
//           },
//           {
//             nome: 'agosto - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 12.77,
//             precoMedioNaoObrigatorio: 22.1,
//             porcentagem: 0,
//           },
//           {
//             nome: 'setembro - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 12.81,
//             precoMedioNaoObrigatorio: 15.68,
//             porcentagem: 0,
//           },
//           {
//             nome: 'outubro - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 15.2,
//             precoMedioNaoObrigatorio: 15.06,
//             porcentagem: 0,
//           },
//           {
//             nome: 'dezembro - 2023',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 0,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0.02,
//             precoMedioNaoObrigatorio: 0.01,
//             porcentagem: 0,
//           },
//         ],
//         histogramaProduto: [
//           {
//             nome: '5',
//             total: 0,
//             totalObrigatorio: 98,
//             totalNaoObrigatorio: 386,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '10',
//             total: 0,
//             totalObrigatorio: 71,
//             totalNaoObrigatorio: 210,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '15',
//             total: 0,
//             totalObrigatorio: 107,
//             totalNaoObrigatorio: 261,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '20',
//             total: 0,
//             totalObrigatorio: 112,
//             totalNaoObrigatorio: 288,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '25',
//             total: 0,
//             totalObrigatorio: 65,
//             totalNaoObrigatorio: 173,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '30',
//             total: 0,
//             totalObrigatorio: 38,
//             totalNaoObrigatorio: 154,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '35',
//             total: 0,
//             totalObrigatorio: 15,
//             totalNaoObrigatorio: 72,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '40',
//             total: 0,
//             totalObrigatorio: 10,
//             totalNaoObrigatorio: 32,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '45',
//             total: 0,
//             totalObrigatorio: 1,
//             totalNaoObrigatorio: 13,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '50',
//             total: 0,
//             totalObrigatorio: 7,
//             totalNaoObrigatorio: 18,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '55',
//             total: 0,
//             totalObrigatorio: 1,
//             totalNaoObrigatorio: 9,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '60',
//             total: 0,
//             totalObrigatorio: 6,
//             totalNaoObrigatorio: 12,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '65',
//             total: 0,
//             totalObrigatorio: 4,
//             totalNaoObrigatorio: 10,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '70',
//             total: 0,
//             totalObrigatorio: 3,
//             totalNaoObrigatorio: 9,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '75',
//             total: 0,
//             totalObrigatorio: 6,
//             totalNaoObrigatorio: 13,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '80',
//             total: 0,
//             totalObrigatorio: 2,
//             totalNaoObrigatorio: 12,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '85',
//             total: 0,
//             totalObrigatorio: 1,
//             totalNaoObrigatorio: 7,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '90',
//             total: 0,
//             totalObrigatorio: 4,
//             totalNaoObrigatorio: 13,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '95',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 3,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '100',
//             total: 0,
//             totalObrigatorio: 3,
//             totalNaoObrigatorio: 7,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '105',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 1,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '110',
//             total: 0,
//             totalObrigatorio: 1,
//             totalNaoObrigatorio: 2,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '115',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 3,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '120',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 2,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '125',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 1,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '130',
//             total: 0,
//             totalObrigatorio: 1,
//             totalNaoObrigatorio: 1,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '140',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 1,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '150',
//             total: 0,
//             totalObrigatorio: 1,
//             totalNaoObrigatorio: 2,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '170',
//             total: 0,
//             totalObrigatorio: 1,
//             totalNaoObrigatorio: 2,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '180',
//             total: 0,
//             totalObrigatorio: 2,
//             totalNaoObrigatorio: 2,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '210',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 1,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//           {
//             nome: '6095',
//             total: 0,
//             totalObrigatorio: 0,
//             totalNaoObrigatorio: 1,
//             mes: null,
//             ano: 0,
//             precoMedioObrigatorio: 0,
//             precoMedioNaoObrigatorio: 0,
//             porcentagem: 0,
//           },
//         ],
//         mediaProduto: [
//           {
//             nome: 'NUGGETS SADIA SABORES 300G',
//             regional: [
//               {
//                 nome: 'SUL',
//                 media: 11.93,
//                 mediana: 10.48,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 10.26,
//                 mediana: 10.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 10.17,
//                 mediana: 9.98,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 9.73,
//                 mediana: 9.99,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'PIZZA SADIA SABORES 440/460/480G ',
//             regional: [
//               {
//                 nome: 'SE/CO',
//                 media: 16.57,
//                 mediana: 16.32,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 16.35,
//                 mediana: 16.99,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 16.08,
//                 mediana: 14.98,
//               },
//               {
//                 nome: 'SUL',
//                 media: 15.78,
//                 mediana: 0,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: "MAC'N CHEESE SADIA SABORES 350G",
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 9.75,
//                 mediana: 8.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 9.19,
//                 mediana: 8.99,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 9.09,
//                 mediana: 8.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 8.61,
//                 mediana: 8.97,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'Pizza Sadia Sabores 440/460g',
//             regional: [
//               {
//                 nome: 'SAO PAULO',
//                 media: 16.75,
//                 mediana: 16.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 15.68,
//                 mediana: 14.44,
//               },
//               {
//                 nome: 'SUL',
//                 media: 11.14,
//                 mediana: 0,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 10.25,
//                 mediana: 0.01,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'HAMBURGUER PERDIGÃO SABORES 672g',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 18.82,
//                 mediana: 17.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 17.06,
//                 mediana: 22.49,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 16.57,
//                 mediana: 16.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 14.45,
//                 mediana: 15.96,
//               },
//             ],
//           },
//           {
//             nome: 'PEITO DE PERU DEFUMADO SADIA',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 55.38,
//                 mediana: 54.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 41.8,
//                 mediana: 45.74,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 30.33,
//                 mediana: 42.9,
//               },
//               {
//                 nome: 'SUL',
//                 media: 12.1,
//                 mediana: 5.99,
//               },
//             ],
//           },
//           {
//             nome: 'MACARRÃO HOT BOWLS SADIA SABORES 300g',
//             regional: [
//               {
//                 nome: 'SAO PAULO',
//                 media: 8.84,
//                 mediana: 8.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 7.9,
//                 mediana: 8.1,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 7.9,
//                 mediana: 0.01,
//               },
//               {
//                 nome: 'SUL',
//                 media: 6.49,
//                 mediana: 0,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'MINI CHICKEN  PERDIGÃO SABORES 275G',
//             regional: [
//               {
//                 nome: 'SUL',
//                 media: 11.15,
//                 mediana: 10.99,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 9.19,
//                 mediana: 8.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 9.18,
//                 mediana: 8.48,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 7.7,
//                 mediana: 7.59,
//               },
//             ],
//           },
//           {
//             nome: 'LASANHA PERDIGÃO SABORES 600G',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 14.37,
//                 mediana: 13.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 12.67,
//                 mediana: 13.54,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 12.27,
//                 mediana: 11.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 12.16,
//                 mediana: 8.98,
//               },
//             ],
//           },
//           {
//             nome: 'Nuggets Sadia Sabores 300g',
//             regional: [
//               {
//                 nome: 'SAO PAULO',
//                 media: 10.14,
//                 mediana: 9.99,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 9.73,
//                 mediana: 10.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 9.22,
//                 mediana: 9.49,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 6.35,
//                 mediana: 0.01,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'nulo',
//             regional: [
//               {
//                 nome: 'SUL',
//                 media: 0,
//                 mediana: 0,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 0,
//                 mediana: 0,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 0,
//                 mediana: 0,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 0,
//                 mediana: 0,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'MARGARINA QUALY SABORES 500G',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 8.73,
//                 mediana: 8.95,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 8.56,
//                 mediana: 8.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 8.36,
//                 mediana: 7.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 7.81,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'STEAK DE FRANGO PERDIGÃO 100G',
//             regional: [
//               {
//                 nome: 'SE/CO',
//                 media: 2.22,
//                 mediana: 2.19,
//               },
//               {
//                 nome: 'SUL',
//                 media: 2.21,
//                 mediana: 1.94,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 2.13,
//                 mediana: 2.16,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 1.99,
//                 mediana: 2.19,
//               },
//             ],
//           },
//           {
//             nome: 'PRESUNTO COZIDO PERDIGÃO',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 27.05,
//                 mediana: 29.97,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 20.84,
//                 mediana: 23.8,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 14.34,
//                 mediana: 3.19,
//               },
//               {
//                 nome: 'SUL',
//                 media: 8.16,
//                 mediana: 2.79,
//               },
//             ],
//           },
//           {
//             nome: 'Lasanha Perdigão Sabores 600g',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 13.77,
//                 mediana: 13.99,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 12.05,
//                 mediana: 12.98,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 12.04,
//                 mediana: 11.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 9.31,
//                 mediana: 8.48,
//               },
//             ],
//           },
//           {
//             nome: 'LASANHA SADIA SABORES 600G',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 15.39,
//                 mediana: 14.99,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 14.76,
//                 mediana: 13.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 14.18,
//                 mediana: 13.44,
//               },
//               {
//                 nome: 'SUL',
//                 media: 12.31,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'MORTADELA OURO PERDIGÃO ',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 26.03,
//                 mediana: 26.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 22.04,
//                 mediana: 21.98,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 12.66,
//                 mediana: 2.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 4.42,
//                 mediana: 2.94,
//               },
//             ],
//           },
//           {
//             nome: 'SALSICHA SADIA SABORES 500G ',
//             regional: [
//               {
//                 nome: 'SUL',
//                 media: 12.64,
//                 mediana: 12.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 11.69,
//                 mediana: 11.9,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 11.55,
//                 mediana: 11.99,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 11.11,
//                 mediana: 11.24,
//               },
//             ],
//           },
//           {
//             nome: 'SALSICHA HOT DOG PERDIGÃO ',
//             regional: [
//               {
//                 nome: 'SE/CO',
//                 media: 23.18,
//                 mediana: 15.9,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 14.15,
//                 mediana: 13.19,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 13.3,
//                 mediana: 13.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'MORTADELA DEFUMADA SADIA ',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 24.07,
//                 mediana: 23.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 18.28,
//                 mediana: 21.62,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 9.76,
//                 mediana: 2.65,
//               },
//               {
//                 nome: 'SUL',
//                 media: 2.49,
//                 mediana: 2.49,
//               },
//             ],
//           },
//           {
//             nome: 'LINGUIÇA TOSCANA PERDIGÃO NA BRASA SABORES 600G',
//             regional: [
//               {
//                 nome: 'SAO PAULO',
//                 media: 26.88,
//                 mediana: 24.99,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 24.05,
//                 mediana: 22.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 22.97,
//                 mediana: 0,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 19.66,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'Steak Perdigão 100g',
//             regional: [
//               {
//                 nome: 'SUL',
//                 media: 2.25,
//                 mediana: 1.99,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 2.09,
//                 mediana: 1.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 1.83,
//                 mediana: 1.98,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 1.62,
//                 mediana: 1.75,
//               },
//             ],
//           },
//           {
//             nome: 'Hambúrguer Perdigão Sabores 672g',
//             regional: [
//               {
//                 nome: 'SUL',
//                 media: 19.98,
//                 mediana: 0,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 19.39,
//                 mediana: 19.5,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 18.24,
//                 mediana: 17.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 15.24,
//                 mediana: 13.98,
//               },
//             ],
//           },
//           {
//             nome: 'Lasanha Sadia Sabores 600g',
//             regional: [
//               {
//                 nome: 'SAO PAULO',
//                 media: 13.46,
//                 mediana: 12.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 13,
//                 mediana: 12.9,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 10.71,
//                 mediana: 0.01,
//               },
//               {
//                 nome: 'SUL',
//                 media: 9.47,
//                 mediana: 0,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'MARGARINA  BECEL SABORES 500G',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 10.11,
//                 mediana: 9.89,
//               },
//               {
//                 nome: 'SUL',
//                 media: 9.94,
//                 mediana: 8.98,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 9.24,
//                 mediana: 9.44,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 9.06,
//                 mediana: 8.99,
//               },
//             ],
//           },
//           {
//             nome: 'Peito de Peru Sadia',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 44.75,
//                 mediana: 43.45,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 29.43,
//                 mediana: 39.45,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 26.54,
//                 mediana: 5.14,
//               },
//             ],
//           },
//           {
//             nome: 'Hot Pocket Sadia Sabores 145g',
//             regional: [
//               {
//                 nome: 'SAO PAULO',
//                 media: 7.23,
//                 mediana: 7.39,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 6.65,
//                 mediana: 5.98,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 5.91,
//                 mediana: 6.99,
//               },
//               {
//                 nome: 'SUL',
//                 media: 5.2,
//                 mediana: 5.74,
//               },
//             ],
//           },
//           {
//             nome: 'HAMBÚRGUER SADIA SABORES 672G',
//             regional: [
//               {
//                 nome: 'SUL',
//                 media: 24.62,
//                 mediana: 24.44,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 21.54,
//                 mediana: 21.9,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 20.03,
//                 mediana: 19.99,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 18.28,
//                 mediana: 17.99,
//               },
//             ],
//           },
//           {
//             nome: 'LINGUIÇA TOSCANA PERDIGÃO NA BRASA SABORES 1KG',
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 media: 26.95,
//                 mediana: 27.9,
//               },
//               {
//                 nome: 'SUL',
//                 media: 25.4,
//                 mediana: 0,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 24.9,
//                 mediana: 26.9,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 media: 23.99,
//                 mediana: 0,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//           {
//             nome: 'Steak Sadia 100g',
//             regional: [
//               {
//                 nome: 'SAO PAULO',
//                 media: 2.35,
//                 mediana: 2.34,
//               },
//               {
//                 nome: 'SE/CO',
//                 media: 1.82,
//                 mediana: 1.99,
//               },
//               {
//                 nome: 'NO/NE',
//                 media: 1.04,
//                 mediana: 0.01,
//               },
//               {
//                 nome: 'SUL',
//                 media: 0,
//                 mediana: 0,
//               },
//             ],
//           },
//         ],
//         labelsRegional: [
//           'NO/NE',
//           'SAO PAULO',
//           'SE/CO',
//           'SUL',
//           'TRADE COORPORATIVO',
//         ],
//       },
//       gerencial: {
//         verbaBrasil: {
//           verbaDisponivel: 3201485.41,
//           verbaUtilizada: 1366731.65,
//         },
//         verbaRegional: [
//           {
//             nome: 'NO/NE',
//             totalCusto: 162855,
//             totalVerba: 126331,
//             porcentagem: 128,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SAO PAULO',
//             totalCusto: 599924,
//             totalVerba: 1610769,
//             porcentagem: 37,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SE/CO',
//             totalCusto: 518648,
//             totalVerba: 392776,
//             porcentagem: 132,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SUL',
//             totalCusto: 114565,
//             totalVerba: 168963,
//             porcentagem: 67,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'TRADE COORPORATIVO',
//             totalCusto: 18403,
//             totalVerba: 159412,
//             porcentagem: 11,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//         ],
//         verbaDisponivel: [],
//         verbaMaterial: [
//           {
//             nome: 'ENCARTE FRENTE E VERSO',
//             totalCusto: 646858,
//             totalVerba: 0,
//             porcentagem: 45.75,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'BANNER ESTÁTICO P/ SITE',
//             totalCusto: 149303,
//             totalVerba: 0,
//             porcentagem: 10.59,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//             totalCusto: 114020,
//             totalVerba: 0,
//             porcentagem: 8.12,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'POST REDES SOCIAIS',
//             totalCusto: 112011,
//             totalVerba: 0,
//             porcentagem: 7.94,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'BOX PROMOCIONAL',
//             totalCusto: 77260,
//             totalVerba: 0,
//             porcentagem: 5.46,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'VIDEO',
//             totalCusto: 61290,
//             totalVerba: 0,
//             porcentagem: 4.87,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'ADESIVO P/ FREEZER',
//             totalCusto: 38723,
//             totalVerba: 0,
//             porcentagem: 2.74,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'LÂMINA',
//             totalCusto: 36217,
//             totalVerba: 0,
//             porcentagem: 2.59,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'CARTAZ',
//             totalCusto: 26950,
//             totalVerba: 0,
//             porcentagem: 1.92,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'ADESIVO',
//             totalCusto: 23568,
//             totalVerba: 0,
//             porcentagem: 1.68,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'BANNER FÍSICO',
//             totalCusto: 20937,
//             totalVerba: 0,
//             porcentagem: 1.5,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'EMAIL MKT',
//             totalCusto: 14263,
//             totalVerba: 0,
//             porcentagem: 1.01,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'VIDEO ATÉ 30S',
//             totalCusto: 14101,
//             totalVerba: 0,
//             porcentagem: 1,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'BACKLIGHT',
//             totalCusto: 13190,
//             totalVerba: 0,
//             porcentagem: 0.93,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'ENCARTE 4 PÁGINAS',
//             totalCusto: 12520,
//             totalVerba: 0,
//             porcentagem: 1.16,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'BOX MEIA PAGINA',
//             totalCusto: 11226,
//             totalVerba: 0,
//             porcentagem: 0.81,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'BANNER ANIMADO P/ SITE',
//             totalCusto: 10936,
//             totalVerba: 0,
//             porcentagem: 0.79,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'TABLE TENT',
//             totalCusto: 9762,
//             totalVerba: 0,
//             porcentagem: 0.69,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'TOTEM',
//             totalCusto: 9083,
//             totalVerba: 0,
//             porcentagem: 0.64,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SPOT DE RÁDIO (*)',
//             totalCusto: 4235,
//             totalVerba: 0,
//             porcentagem: 0.3,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'JOGO AMERICANO',
//             totalCusto: 3155,
//             totalVerba: 0,
//             porcentagem: 0.22,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'FLYER',
//             totalCusto: 2554,
//             totalVerba: 0,
//             porcentagem: 0.18,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'CATÁLOGO',
//             totalCusto: 921,
//             totalVerba: 0,
//             porcentagem: 0.07,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'STOPPER',
//             totalCusto: 621,
//             totalVerba: 0,
//             porcentagem: 0.04,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SPLASH',
//             totalCusto: 585,
//             totalVerba: 0,
//             porcentagem: 0.04,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'WOBBLER',
//             totalCusto: 96,
//             totalVerba: 0,
//             porcentagem: 0.02,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//         ],
//         verbaCanal: [
//           {
//             nome: 'HSA',
//             totalCusto: 868160,
//             totalVerba: 0,
//             porcentagem: 61,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'C&C Regional',
//             totalCusto: 271318,
//             totalVerba: 0,
//             porcentagem: 19,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SEM REGRA',
//             totalCusto: 106462,
//             totalVerba: 0,
//             porcentagem: 7,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'C&C Nacional',
//             totalCusto: 69034,
//             totalVerba: 0,
//             porcentagem: 4,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'KAR',
//             totalCusto: 56154,
//             totalVerba: 0,
//             porcentagem: 3,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'AS Nacional',
//             totalCusto: 43268,
//             totalVerba: 0,
//             porcentagem: 3,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//         ],
//         solicProduto: [
//           {
//             nome: 'nulo',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 318,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'NUGGETS SADIA SABORES 300G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 290,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'PIZZA SADIA SABORES 440/460/480G ',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 279,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: "MAC'N CHEESE SADIA SABORES 350G",
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 234,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'LASANHA PERDIGÃO SABORES 600G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 221,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'MORTADELA OURO PERDIGÃO ',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 184,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'LASANHA SADIA SABORES 600G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 179,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'MINI CHICKEN  PERDIGÃO SABORES 275G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 172,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'PRESUNTO COZIDO S/CAPA GORD SADIA',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 170,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'Nuggets Sadia Sabores 300g',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 170,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'PEITO DE PERU DEFUMADO SADIA',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 167,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'HAMBURGUER PERDIGÃO SABORES 672g',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 161,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'MARGARINA QUALY SABORES 500G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 152,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'MACARRÃO HOT BOWLS SADIA SABORES ',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 147,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'Pizza Sadia Sabores 440/460g',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 145,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'STEAK DE FRANGO PERDIGÃO 100G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 144,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'PRESUNTO COZIDO PERDIGÃO',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 144,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'LINGUIÇA TOSCANA PERDIGÃO NA BRASA SABORES 600G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 141,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'MORTADELA DEFUMADA SADIA ',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 138,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SALSICHA SADIA SABORES 500G ',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 136,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SALSICHA PERDIGÃO SABORES 500G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 136,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'MACARRÃO HOT BOWLS SADIA SABORES 300g',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 134,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'Lasanha Perdigão Sabores 600g',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 131,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'HOT POCKET SADIA SABORES 145G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 130,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'PRESUNTO COZIDO SOLTISSIMO SADIA 200G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 127,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'MARGARINA  BECEL SABORES 500G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 127,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'BIG CHICKEN PERDIGÃO SABORES 1KG',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 125,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'MEU MENU PERDIGÃO SABORES 350G',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 124,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'LINGUIÇA TOSCANA PERDIGÃO NA BRASA SABORES 1KG',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 124,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'Lasanha Sadia Sabores 600g',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 123,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//         ],
//         solicitanteRegional: [
//           {
//             nome: 'SE/CO',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 720,
//             ano: 0,
//             regional: null,
//             solicitante: [
//               {
//                 nome: 'CLARICE RANGEL DE ALMEIDA BERTOLINI',
//                 totalSolicitacao: 124,
//               },
//               {
//                 nome: 'YGOR BERRIEL DUARTE',
//                 totalSolicitacao: 47,
//               },
//               {
//                 nome: 'HALLEY DE MORAIS SILVA',
//                 totalSolicitacao: 45,
//               },
//               {
//                 nome: 'MILENA XIMENES',
//                 totalSolicitacao: 35,
//               },
//               {
//                 nome: 'DEBORAH DA SILVEIRA OLIVEIRA',
//                 totalSolicitacao: 32,
//               },
//               {
//                 nome: 'GLAUCE DA SILVA BARBARA',
//                 totalSolicitacao: 31,
//               },
//               {
//                 nome: 'HEBERSON BARBOZA DE SOUZA',
//                 totalSolicitacao: 31,
//               },
//               {
//                 nome: 'ESTER RODRIGUES CAVALCANTI',
//                 totalSolicitacao: 28,
//               },
//               {
//                 nome: 'JONATHAN CARVALHO DA SILVA',
//                 totalSolicitacao: 27,
//               },
//               {
//                 nome: 'RODRIGO RAIMUNDI GOUVEA',
//                 totalSolicitacao: 26,
//               },
//             ],
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SAO PAULO',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 628,
//             ano: 0,
//             regional: null,
//             solicitante: [
//               {
//                 nome: 'EMERSON ROLDAO DE JESUS',
//                 totalSolicitacao: 49,
//               },
//               {
//                 nome: 'FERNANDO CURBAN CROCCO',
//                 totalSolicitacao: 42,
//               },
//               {
//                 nome: 'MARCOS VINCO ',
//                 totalSolicitacao: 41,
//               },
//               {
//                 nome: 'TIAGO MENDES',
//                 totalSolicitacao: 41,
//               },
//               {
//                 nome: 'CAMILA PAVAN TOZELLI',
//                 totalSolicitacao: 35,
//               },
//               {
//                 nome: 'ROBSON TADEU MARQUES VAZ',
//                 totalSolicitacao: 31,
//               },
//               {
//                 nome: 'EMERSON SOUZA PEREIRA',
//                 totalSolicitacao: 30,
//               },
//               {
//                 nome: 'WAGNER BAIO',
//                 totalSolicitacao: 28,
//               },
//               {
//                 nome: 'KAREN MARTIN BRANDAO',
//                 totalSolicitacao: 25,
//               },
//               {
//                 nome: 'RENATA VICTORIA DE SOUZA BERNARDO',
//                 totalSolicitacao: 20,
//               },
//             ],
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'NO/NE',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 364,
//             ano: 0,
//             regional: null,
//             solicitante: [
//               {
//                 nome: 'MARIANA VITORIA DO PRADO BARBOSA',
//                 totalSolicitacao: 76,
//               },
//               {
//                 nome: 'THIAGO ROCHA',
//                 totalSolicitacao: 70,
//               },
//               {
//                 nome: 'ALVARO SEABRA',
//                 totalSolicitacao: 69,
//               },
//               {
//                 nome: 'BRUNA ELIZABETH',
//                 totalSolicitacao: 60,
//               },
//               {
//                 nome: 'JOÃO PEDRO MOTTA LOURENÇO',
//                 totalSolicitacao: 22,
//               },
//               {
//                 nome: 'FERNANDO CURBAN CROCCO',
//                 totalSolicitacao: 14,
//               },
//               {
//                 nome: 'SERGIO ROBERTO RODRIGUES MIRANDA JUNIOR',
//                 totalSolicitacao: 12,
//               },
//               {
//                 nome: 'NICHOLAS DOS SANTOS MALCHER',
//                 totalSolicitacao: 7,
//               },
//               {
//                 nome: 'FLAVIO SOBRINHO BRITO',
//                 totalSolicitacao: 6,
//               },
//               {
//                 nome: 'JOSEANA ARANTES PEREIRA',
//                 totalSolicitacao: 6,
//               },
//             ],
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'SUL',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 284,
//             ano: 0,
//             regional: null,
//             solicitante: [
//               {
//                 nome: 'CLOVIANE CAMARGO DE ABREU SANTUCCI',
//                 totalSolicitacao: 42,
//               },
//               {
//                 nome: 'ALEX MESSIAS',
//                 totalSolicitacao: 40,
//               },
//               {
//                 nome: 'LOIR CAMILO MAURINA',
//                 totalSolicitacao: 30,
//               },
//               {
//                 nome: 'JUNI USIEL REICHERT DA SILVA',
//                 totalSolicitacao: 30,
//               },
//               {
//                 nome: 'THAYS TALYTA PEREIRA',
//                 totalSolicitacao: 27,
//               },
//               {
//                 nome: 'WILLIAM TERTULIANO DAGOSTIN',
//                 totalSolicitacao: 26,
//               },
//               {
//                 nome: 'CRISTIANE DAL COL',
//                 totalSolicitacao: 19,
//               },
//               {
//                 nome: 'NIVALDO PRADO GONCALVES JUNIOR',
//                 totalSolicitacao: 10,
//               },
//               {
//                 nome: 'LILIAN DE ALMEIDA CORDEIRO',
//                 totalSolicitacao: 10,
//               },
//               {
//                 nome: 'CRISTIANO DE ALMEIDA DIAS',
//                 totalSolicitacao: 9,
//               },
//             ],
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'TRADE COORPORATIVO',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 17,
//             ano: 0,
//             regional: null,
//             solicitante: [
//               {
//                 nome: 'MÔNICA RICARDO DE VASCONCELOS ANDRADE',
//                 totalSolicitacao: 6,
//               },
//               {
//                 nome: 'ANDRESSA VALETTE GONCALVES',
//                 totalSolicitacao: 4,
//               },
//               {
//                 nome: 'AMANDA FERNANDES',
//                 totalSolicitacao: 3,
//               },
//               {
//                 nome: 'DANIELA CARRARA SANCHES ANGELIM',
//                 totalSolicitacao: 3,
//               },
//               {
//                 nome: 'NAYARA ROCHA FERREIRA',
//                 totalSolicitacao: 1,
//               },
//             ],
//             material: null,
//             canal: null,
//           },
//         ],
//         visaoBrasil: [
//           {
//             nome: 'outubro - 2022',
//             totalSolicitacao: 168,
//             totalSolicitacaoAprovado: 162,
//             totalArte: 148,
//             totalSolicitacaoEncarte: 16,
//             totalEncarteImpresso: 96000,
//             totalVerbaRegional: 630000,
//             totalVerbaUtilizada: 78402,
//             porcentagemVerba: 12,
//             totalVerbaDisponivel: 551598,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'novembro - 2022',
//             totalSolicitacao: 278,
//             totalSolicitacaoAprovado: 268,
//             totalArte: 245,
//             totalSolicitacaoEncarte: 26,
//             totalEncarteImpresso: 134000,
//             totalVerbaRegional: 552917,
//             totalVerbaUtilizada: 150002,
//             porcentagemVerba: 27,
//             totalVerbaDisponivel: 402915,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'dezembro - 2022',
//             totalSolicitacao: 157,
//             totalSolicitacaoAprovado: 153,
//             totalArte: 144,
//             totalSolicitacaoEncarte: 16,
//             totalEncarteImpresso: 63000,
//             totalVerbaRegional: 410483,
//             totalVerbaUtilizada: 76776,
//             porcentagemVerba: 18,
//             totalVerbaDisponivel: 333707,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'janeiro - 2023',
//             totalSolicitacao: 72,
//             totalSolicitacaoAprovado: 69,
//             totalArte: 62,
//             totalSolicitacaoEncarte: 11,
//             totalEncarteImpresso: 44000,
//             totalVerbaRegional: 40466,
//             totalVerbaUtilizada: 39764,
//             porcentagemVerba: 98,
//             totalVerbaDisponivel: 702,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'fevereiro - 2023',
//             totalSolicitacao: 64,
//             totalSolicitacaoAprovado: 63,
//             totalArte: 58,
//             totalSolicitacaoEncarte: 11,
//             totalEncarteImpresso: 75000,
//             totalVerbaRegional: 46473,
//             totalVerbaUtilizada: 46946,
//             porcentagemVerba: 101,
//             totalVerbaDisponivel: -473,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'março - 2023',
//             totalSolicitacao: 152,
//             totalSolicitacaoAprovado: 147,
//             totalArte: 133,
//             totalSolicitacaoEncarte: 34,
//             totalEncarteImpresso: 183000,
//             totalVerbaRegional: 119870,
//             totalVerbaUtilizada: 77481,
//             porcentagemVerba: 64,
//             totalVerbaDisponivel: 42389,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'abril - 2023',
//             totalSolicitacao: 148,
//             totalSolicitacaoAprovado: 142,
//             totalArte: 133,
//             totalSolicitacaoEncarte: 37,
//             totalEncarteImpresso: 203000,
//             totalVerbaRegional: 107760,
//             totalVerbaUtilizada: 106321,
//             porcentagemVerba: 98,
//             totalVerbaDisponivel: 1439,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'maio - 2023',
//             totalSolicitacao: 182,
//             totalSolicitacaoAprovado: 175,
//             totalArte: 172,
//             totalSolicitacaoEncarte: 44,
//             totalEncarteImpresso: 199000,
//             totalVerbaRegional: 137181,
//             totalVerbaUtilizada: 110612,
//             porcentagemVerba: 80,
//             totalVerbaDisponivel: 26569,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'junho - 2023',
//             totalSolicitacao: 220,
//             totalSolicitacaoAprovado: 217,
//             totalArte: 204,
//             totalSolicitacaoEncarte: 44,
//             totalEncarteImpresso: 170000,
//             totalVerbaRegional: 240438,
//             totalVerbaUtilizada: 164711,
//             porcentagemVerba: 68,
//             totalVerbaDisponivel: 75727,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'julho - 2023',
//             totalSolicitacao: 191,
//             totalSolicitacaoAprovado: 184,
//             totalArte: 169,
//             totalSolicitacaoEncarte: 29,
//             totalEncarteImpresso: 144000,
//             totalVerbaRegional: 154733,
//             totalVerbaUtilizada: 138407,
//             porcentagemVerba: 89,
//             totalVerbaDisponivel: 16326,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'agosto - 2023',
//             totalSolicitacao: 175,
//             totalSolicitacaoAprovado: 173,
//             totalArte: 161,
//             totalSolicitacaoEncarte: 41,
//             totalEncarteImpresso: 204000,
//             totalVerbaRegional: 172640,
//             totalVerbaUtilizada: 172640,
//             porcentagemVerba: 100,
//             totalVerbaDisponivel: 0,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'setembro - 2023',
//             totalSolicitacao: 97,
//             totalSolicitacaoAprovado: 97,
//             totalArte: 94,
//             totalSolicitacaoEncarte: 28,
//             totalEncarteImpresso: 105000,
//             totalVerbaRegional: 97919,
//             totalVerbaUtilizada: 75103,
//             porcentagemVerba: 76,
//             totalVerbaDisponivel: 22816,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'outubro - 2023',
//             totalSolicitacao: 108,
//             totalSolicitacaoAprovado: 103,
//             totalArte: 83,
//             totalSolicitacaoEncarte: 23,
//             totalEncarteImpresso: 176000,
//             totalVerbaRegional: 490600,
//             totalVerbaUtilizada: 103852,
//             porcentagemVerba: 21,
//             totalVerbaDisponivel: 386748,
//             ano: 0,
//             mes: null,
//           },
//           {
//             nome: 'dezembro - 2023',
//             totalSolicitacao: 1,
//             totalSolicitacaoAprovado: 0,
//             totalArte: 0,
//             totalSolicitacaoEncarte: 1,
//             totalEncarteImpresso: 0,
//             totalVerbaRegional: 0,
//             totalVerbaUtilizada: 0,
//             porcentagemVerba: 0,
//             totalVerbaDisponivel: 0,
//             ano: 0,
//             mes: null,
//           },
//         ],
//         verbaRegionalMensal: [
//           {
//             nome: 'outubro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 8596,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 25127,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 30108,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 14570,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'novembro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 18952,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 28923,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 77682,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 24444,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'dezembro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 12139,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 28335,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 30890,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 5410,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'janeiro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 5978,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 18819,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 12554,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 2412,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'fevereiro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 10691,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 11579,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 17470,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 7204,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'março - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 2888,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 39464,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 22650,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 11292,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 totalSolicitacao: 1185,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'abril - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 12177,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 58402,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 28063,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 6063,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 totalSolicitacao: 1614,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'maio - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 10474,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 65542,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 29457,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 5138,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'junho - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 34872,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 87843,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 34121,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 6629,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 totalSolicitacao: 1244,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'julho - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 17141,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 48620,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 63210,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 9434,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'agosto - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 11759,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 81625,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 63782,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 10549,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 totalSolicitacao: 4922,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'setembro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 6502,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 27519,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 27047,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 5841,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 totalSolicitacao: 8192,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'outubro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 10023,
//               },
//               {
//                 nome: 'SAO PAULO',
//                 totalSolicitacao: 70249,
//               },
//               {
//                 nome: 'SE/CO',
//                 totalSolicitacao: 19829,
//               },
//               {
//                 nome: 'SUL',
//                 totalSolicitacao: 3750,
//               },
//               {
//                 nome: 'TRADE COORPORATIVO',
//                 totalSolicitacao: 0,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//           {
//             nome: 'dezembro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: [
//               {
//                 nome: 'NO/NE',
//                 totalSolicitacao: 0,
//               },
//             ],
//             solicitante: null,
//             material: null,
//             canal: null,
//           },
//         ],
//         verbaMaterialMensal: [
//           {
//             nome: 'outubro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 1147,
//               },
//               {
//                 nome: 'BACKLIGHT',
//                 totalSolicitacao: 2808,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 1785,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 17127,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 279,
//               },
//               {
//                 nome: 'BOX MEIA PAGINA',
//                 totalSolicitacao: 2854,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 2088,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 2581,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 2295,
//               },
//               {
//                 nome: 'ENCARTE 4 PÁGINAS',
//                 totalSolicitacao: 144,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 25445,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 3820,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 7483,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 7667,
//               },
//               {
//                 nome: 'TOTEM',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 777,
//               },
//               {
//                 nome: 'WOBBLER',
//                 totalSolicitacao: 96,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'novembro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 5261,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 6994,
//               },
//               {
//                 nome: 'BACKLIGHT',
//                 totalSolicitacao: 4228,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 2040,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 20655,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 3479,
//               },
//               {
//                 nome: 'BOX MEIA PAGINA',
//                 totalSolicitacao: 3128,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 4078,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 6585,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 807,
//               },
//               {
//                 nome: 'ENCARTE 4 PÁGINAS',
//                 totalSolicitacao: 3789,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 30203,
//               },
//               {
//                 nome: 'FLYER',
//                 totalSolicitacao: 1632,
//               },
//               {
//                 nome: 'JOGO AMERICANO',
//                 totalSolicitacao: 1618,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 3015,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 13661,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 23364,
//               },
//               {
//                 nome: 'SPLASH',
//                 totalSolicitacao: 585,
//               },
//               {
//                 nome: 'TABLE TENT',
//                 totalSolicitacao: 2030,
//               },
//               {
//                 nome: 'TOTEM',
//                 totalSolicitacao: 2352,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 7770,
//               },
//               {
//                 nome: 'VIDEO ATÉ 30S',
//                 totalSolicitacao: 2719,
//               },
//               {
//                 nome: 'WOBBLER',
//                 totalSolicitacao: 0,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'dezembro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 2295,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 1551,
//               },
//               {
//                 nome: 'BACKLIGHT',
//                 totalSolicitacao: 859,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 2295,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 8755,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 636,
//               },
//               {
//                 nome: 'BOX MEIA PAGINA',
//                 totalSolicitacao: 2622,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 1776,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 1122,
//               },
//               {
//                 nome: 'CATÁLOGO',
//                 totalSolicitacao: 921,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 595,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 22350,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 2093,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 8821,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 15359,
//               },
//               {
//                 nome: 'TABLE TENT',
//                 totalSolicitacao: 966,
//               },
//               {
//                 nome: 'TOTEM',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 3755,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'janeiro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 286,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 620,
//               },
//               {
//                 nome: 'BACKLIGHT',
//                 totalSolicitacao: 4018,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 9520,
//               },
//               {
//                 nome: 'BOX MEIA PAGINA',
//                 totalSolicitacao: 2392,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 740,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 374,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 425,
//               },
//               {
//                 nome: 'ENCARTE 4 PÁGINAS',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 10355,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 1512,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 2617,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 3017,
//               },
//               {
//                 nome: 'TABLE TENT',
//                 totalSolicitacao: 775,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 2331,
//               },
//               {
//                 nome: 'VIDEO ATÉ 30S',
//                 totalSolicitacao: 777,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'fevereiro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 1933,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 1020,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 3952,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 3608,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 425,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 27143,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 739,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 4129,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 3995,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'março - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 3733,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 644,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 6417,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 5940,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 1190,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 37488,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 1637,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 14209,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 4666,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 1554,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'abril - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 2888,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 8525,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 1978,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 6008,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 823,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 297,
//               },
//               {
//                 nome: 'ENCARTE 4 PÁGINAS',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 58691,
//               },
//               {
//                 nome: 'JOGO AMERICANO',
//                 totalSolicitacao: 170,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 5935,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 6564,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 8644,
//               },
//               {
//                 nome: 'TABLE TENT',
//                 totalSolicitacao: 483,
//               },
//               {
//                 nome: 'TOTEM',
//                 totalSolicitacao: 648,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 3108,
//               },
//               {
//                 nome: 'VIDEO ATÉ 30S',
//                 totalSolicitacao: 1554,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'maio - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 1818,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 310,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 510,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 12214,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 193,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 8728,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 2993,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 2040,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 58156,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 2535,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 2896,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 13812,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 4403,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'junho - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 3646,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 6097,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 2091,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 16880,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 4583,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 10140,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 876,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 1593,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 82284,
//               },
//               {
//                 nome: 'FLYER',
//                 totalSolicitacao: 922,
//               },
//               {
//                 nome: 'JOGO AMERICANO',
//                 totalSolicitacao: 1367,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 3998,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 10201,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 10176,
//               },
//               {
//                 nome: 'TABLE TENT',
//                 totalSolicitacao: 5506,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'VIDEO ATÉ 30S',
//                 totalSolicitacao: 4344,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'julho - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 445,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 9755,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 1195,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 16470,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 3256,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 10218,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 61734,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 3830,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 17175,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 6025,
//               },
//               {
//                 nome: 'STOPPER',
//                 totalSolicitacao: 621,
//               },
//               {
//                 nome: 'TOTEM',
//                 totalSolicitacao: 1140,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 5090,
//               },
//               {
//                 nome: 'VIDEO ATÉ 30S',
//                 totalSolicitacao: 1448,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'agosto - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 3197,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 1524,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 16633,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 931,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 8536,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 1753,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 1244,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 91847,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 4089,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 13718,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 8665,
//               },
//               {
//                 nome: 'SPOT DE RÁDIO (*)',
//                 totalSolicitacao: 1800,
//               },
//               {
//                 nome: 'TOTEM',
//                 totalSolicitacao: 380,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 16506,
//               },
//               {
//                 nome: 'VIDEO ATÉ 30S',
//                 totalSolicitacao: 1810,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'setembro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO',
//                 totalSolicitacao: 1449,
//               },
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 609,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 6324,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 185,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 7486,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 4294,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 2104,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 38609,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 1768,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 3734,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 2838,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 5697,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'outubro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ADESIVO P/ FREEZER',
//                 totalSolicitacao: 5792,
//               },
//               {
//                 nome: 'BANNER ANIMADO P/ SITE',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'BANNER ESTÁTICO P/ SITE',
//                 totalSolicitacao: 5826,
//               },
//               {
//                 nome: 'BANNER FÍSICO',
//                 totalSolicitacao: 4576,
//               },
//               {
//                 nome: 'BOX PROMOCIONAL',
//                 totalSolicitacao: 7172,
//               },
//               {
//                 nome: 'CARTAZ',
//                 totalSolicitacao: 1315,
//               },
//               {
//                 nome: 'EMAIL MKT',
//                 totalSolicitacao: 0,
//               },
//               {
//                 nome: 'ENCARTE FRENTE E VERSO',
//                 totalSolicitacao: 63875,
//               },
//               {
//                 nome: 'LÂMINA',
//                 totalSolicitacao: 1239,
//               },
//               {
//                 nome: 'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//                 totalSolicitacao: 6784,
//               },
//               {
//                 nome: 'POST REDES SOCIAIS',
//                 totalSolicitacao: 2639,
//               },
//               {
//                 nome: 'TOTEM',
//                 totalSolicitacao: 380,
//               },
//               {
//                 nome: 'VIDEO',
//                 totalSolicitacao: 4249,
//               },
//             ],
//             canal: null,
//           },
//           {
//             nome: 'dezembro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: [
//               {
//                 nome: 'ENCARTE 4 PÁGINAS',
//                 totalSolicitacao: 0,
//               },
//             ],
//             canal: null,
//           },
//         ],
//         verbaCanalMensal: [
//           {
//             nome: 'outubro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 6137,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 1340,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 16520,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 46785,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 3575,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 4043,
//               },
//             ],
//           },
//           {
//             nome: 'novembro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 15272,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 484,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 51142,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 62574,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 9807,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 10720,
//               },
//             ],
//           },
//           {
//             nome: 'dezembro - 2022',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 2422,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 484,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 14327,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 46243,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 2266,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 11032,
//               },
//             ],
//           },
//           {
//             nome: 'janeiro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 2550,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 4536,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 7015,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 21390,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 3453,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 819,
//               },
//             ],
//           },
//           {
//             nome: 'fevereiro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 279,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 14634,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 23780,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 5673,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 2579,
//               },
//             ],
//           },
//           {
//             nome: 'março - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 3586,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 14431,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 50534,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 6349,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 2579,
//               },
//             ],
//           },
//           {
//             nome: 'abril - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 1942,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 3908,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 19851,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 64554,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 8196,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 7867,
//               },
//             ],
//           },
//           {
//             nome: 'maio - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 127,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 6920,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 20035,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 67703,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 9704,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 6120,
//               },
//             ],
//           },
//           {
//             nome: 'junho - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 3884,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 3805,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 19274,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 120433,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 1958,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 15356,
//               },
//             ],
//           },
//           {
//             nome: 'julho - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 3625,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 10966,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 26154,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 85810,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 11850,
//               },
//             ],
//           },
//           {
//             nome: 'agosto - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 2041,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 6326,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 22020,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 121841,
//               },
//               {
//                 nome: 'KAR',
//                 totalSolicitacao: 1340,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 19069,
//               },
//             ],
//           },
//           {
//             nome: 'setembro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 946,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 12302,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 11258,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 41795,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 8801,
//               },
//             ],
//           },
//           {
//             nome: 'outubro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'AS Nacional',
//                 totalSolicitacao: 697,
//               },
//               {
//                 nome: 'C&C Nacional',
//                 totalSolicitacao: 473,
//               },
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 16348,
//               },
//               {
//                 nome: 'HSA',
//                 totalSolicitacao: 84865,
//               },
//               {
//                 nome: 'SEM REGRA',
//                 totalSolicitacao: 1467,
//               },
//             ],
//           },
//           {
//             nome: 'dezembro - 2023',
//             totalCusto: 0,
//             totalVerba: 0,
//             porcentagem: 0,
//             totalSolicitacao: 0,
//             ano: 0,
//             regional: null,
//             solicitante: null,
//             material: null,
//             canal: [
//               {
//                 nome: 'C&C Regional',
//                 totalSolicitacao: 0,
//               },
//             ],
//           },
//         ],
//         labelsRegional: [
//           'NO/NE',
//           'SAO PAULO',
//           'SE/CO',
//           'SUL',
//           'TRADE COORPORATIVO',
//         ],
//         labelsCanal: [
//           'AS Nacional',
//           'C&C Nacional',
//           'C&C Regional',
//           'HSA',
//           'KAR',
//           'SEM REGRA',
//         ],
//         labelsMaterial: [
//           'ADESIVO',
//           'ADESIVO P/ FREEZER',
//           'BACKLIGHT',
//           'BANNER ANIMADO P/ SITE',
//           'BANNER ESTÁTICO P/ SITE',
//           'BANNER FÍSICO',
//           'BOX MEIA PAGINA',
//           'BOX PROMOCIONAL',
//           'CARTAZ',
//           'CATÁLOGO',
//           'EMAIL MKT',
//           'ENCARTE 4 PÁGINAS',
//           'ENCARTE FRENTE E VERSO',
//           'FLYER',
//           'JOGO AMERICANO',
//           'LÂMINA',
//           'PAINEL  ou BACKLIGHT ou  OUTDOOR',
//           'POST REDES SOCIAIS',
//           'SPLASH',
//           'SPOT DE RÁDIO (*)',
//           'STOPPER',
//           'TABLE TENT',
//           'TOTEM',
//           'VIDEO',
//           'VIDEO ATÉ 30S',
//           'WOBBLER',
//         ],
//       },
//     },
//   },
//   errors: null,
//   exception: null,
// };

// const GET_RELATORIO_GRAFICO = async (filtros) => {
//   // const GET_RELATORIO_GRAFICO = async () => {
//   const { data } = await api.get(`${PATH}/get-relatorio-grafico`, {
//     params: filtros,
//     paramsSerializer: (params) => {
//       return qs.stringify(params);
//     },
//   });
//   // const data = mockData;
//   return mockData;
// };

const GET_RELATORIO_GRAFICO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-relatorio-grafico`, {
    params: filtros,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  // console.log(data);
  return data;
};
export default GET_RELATORIO_GRAFICO;
