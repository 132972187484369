// Permite so numeros inteiros
// Usar em onKeyPress(ev)
export function onlyIntegerNumber(evt) {
  const theEvent = evt || window.event;
  let key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  const regex = /^[0-9]+$/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export function parseIntLocaleString(value) {
  return parseInt(value, 10).toLocaleString('pt-BR');
}

export function formatarMoeda(valor) {
  console.log('valor', valor);
  return `${valor.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  })}`;
}

export default onlyIntegerNumber;
