import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import lineBreaker from 'helpers/LineBreaker';
import { formatarMoeda } from 'util/numberUtil';
import SemDados from '../SemDados';

const RegionalInvestimento = (props) => {
  const { data } = props;
  const titulo = 'TOTAL INVESTIDO POR REGIONAL';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itensAnos = [];
  const itensRegionais = [];
  // popula os arrays de anos e meses
  data.forEach((ano) => {
    itensAnos.push(ano.ano);
    ano.data.forEach((regional) => {
      itensRegionais.push(regional);
    });
  });

  const itensCusto = itensRegionais.map((regional) => {
    return regional.totalCusto;
  });

  const series = [
    {
      name: 'Verba Utilizada',
      data: itensCusto,
    },
  ];

  const maxCusto = Math.max(...itensCusto);
  const maxValue = Math.max(maxCusto);

  const options = {
    colors: ['#e36823', '#001463'],
    legend: {
      showForSingleSeries: true,
      // formatter: (seriesName) => {
      //   return [seriesName.toUpperCase()];
      // },
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      floating: true,
      offsetX: 0,
      offsetY: 6,
    },
    plotOptions: {
      bar: {
        hideZeroBarsWhenGrouped: false,
        horizontal: false,
        columnWidth: '70%',
        dataLabels: {
          position: 'top',
        },
        colors: {},
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 4,
    },
    xaxis: {
      position: 'bottom',
      show: true,
      categories: itensRegionais.map((regional) => {
        return regional.nome;
      }),
      group: {
        style: {
          fontSize: '12px',
          fontWeight: 700,
        },
        groups: data.map((ano) => {
          return {
            title: ano.ano,
            cols: ano.data.length,
          };
        }),
      },
      labels: {
        show: true,
        style: {
          fontWeight: 600,
        },
        formatter: (value) => {
          const lines = lineBreaker(value, 7);
          return lines;
        },
      },
      axisBorder: {
        show: true,
        color: '#a3a3a3',
      },
      axisTicks: {
        show: true,
        color: '#a3a3a3',
      },
    },
    yaxis: {
      max: maxValue * 1.2,
      show: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      parentHeightOffset: 5,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: -19,
      formatter: (value) => {
        return value === null ? formatarMoeda(0) : formatarMoeda(value);
      },
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              Regional: <span id="data">${
                options.xaxis.categories[dataPointIndex]
              }</span>
            </span>
            <span>
              Custo Total: <span id="data">R$${formatarMoeda(
                itensCusto[dataPointIndex]
              )}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico" style={{ overflow: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="scrollable-horizontal">
        <Chart
          options={options}
          series={series}
          type="bar"
          height="250"
          style={{
            width: options.xaxis.categories.length * 100,
            minWidth: '100%',
          }}
        />
      </div>
    </div>
  );
};

export default RegionalInvestimento;
